import React, { PureComponent } from 'react'
import { Card } from 'antd';
import UserAttributeRow from './UserAttributeRow';

export default class ProfileCard extends PureComponent {
    
    render() {
        const { user, attributes } = this.props;
        return (
            <div className="profile-details-container">
                <Card title={user.name} className="profile-card">
                        {attributes.map(attribute => <UserAttributeRow key={attribute} label={attribute} value={user[attribute]} />)}
                </Card>
            </div>
        )
    }
}
