import reducers from '../reducers/index';
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';

/**
 * redux-persist config
 */
const persistConfig = {
    key: "study-planner-root",
    storage: storage
  };

/**
 * Configure Redux Persist
 */
const configurePersistReducer = () => {
    const persistedReducer = persistReducer(persistConfig, reducers);
    
    return persistedReducer;
};

export default configurePersistReducer();