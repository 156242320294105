import React, { Component } from 'react';
import { Row, Empty } from 'antd';
import NoteDetailCard from './NoteDetailCard';
import NoteCard from './NoteCard';

export default class MyNotes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedNote : null
    }
  }

  closeNote = () => {
    this.setState({selectedNote : null});
  }
  showNote = note => {
    this.setState({selectedNote : note});
  }

  editNotes = (noteDetails) => {
    this.setState({selectedNote : null});
    this.props.editNotes(noteDetails)
  }

  render() {
    const { notes,isCollectionEditable } = this.props;
    const { selectedNote } = this.state;
    return (
      <div className="card-sec1 mt--20">
      
      <Row gutter={30}>
        {notes.map(note => <NoteCard note={note} showNote={this.showNote} />)}
        {
          !notes.length && <Empty description="No notes added in this collection"/>
        }
      </Row>
      {selectedNote && <NoteDetailCard isCollectionEditable = {isCollectionEditable} visible={true} isCollectionEditable = {isCollectionEditable} note={selectedNote} onCancel={this.closeNote} editNotes = {this.editNotes} />}
    </div>
    )
  }
}
