/* eslint-disable import/first */
/** Library Imports */
import React, { Component } from 'react'


/** StyleSheet */
import './App.scss';

/** Components */
import AppRouter from './AppRouter';


/** Redux */
import { Provider } from 'react-redux';
import perisistStore from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
const {store, persistor} = perisistStore;

export default class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter/>
        </PersistGate>
      </Provider>
    )
  }
}