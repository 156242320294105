import React, { Component } from 'react';
import { connect } from "react-redux";
import requireAuth from './../hocs/requireAuth';
import { login } from './../actions/authActions'
import * as ROUTES from './../constants/routes';
import ProfileCard from '../components/Profile/ProfileCard';
import { getUserFamilies } from './../actions/userActions';
import { getFamilyUsers, leaveFamily, createFamily } from './../actions/familyActions';
import { Col, Row, Spin } from 'antd';
import FamiliesCard from '../components/Profile/FamiliesCard';
import MembersList from '../components/Profile/MembersList';
import CreateFamilyModal from '../components/Profile/CreateFamilyModal'
import JoinFamilyModal from '../components/Profile/JoinFamilyModal'
import AddFamilyMemberModal from '../components/Profile/AddFamilyMemberModal'
import MemberRequestModal from '../components/Profile/MemberRequestModal'
import find from 'lodash/find'

class ProfileScreen extends Component {

    state = {
        attributes: [
            'username', 'email', 'phone'
        ],
        familyUsers: null,
        loader: false,
        familyId: null,
        createFamilyModal: false,
        joinFamilyModal: false,
        addFamilyMemberModal: false,
        memberRequestModal:false
    }

    componentDidMount() {
        const { getUserFamilies } = this.props;
        getUserFamilies();
    }

    startLoader = () => {
        this.setState({ loader: true })
    }

    stopLoader = () => {
        this.setState({ loader: false })
    }

    leaveFamily = familyId => {
        this.startLoader()
        const { leaveFamily, getUserFamilies } = this.props;
        leaveFamily(familyId).then(res => {
            getUserFamilies();
            this.stopLoader()
        }).catch(err => {
            this.stopLoader()
        });
    }

    loadFamilyMembers = familyId => {
        this.startLoader();
        const { getFamilyUsers } = this.props;
        getFamilyUsers(familyId).then(users => {
            this.stopLoader();
            this.setState({ familyUsers: users, familyId });
        }).catch(err => {
            this.stopLoader()
        });
    }

    createFamily = (values) => {

        this.startLoader();
        const { createFamily, getUserFamilies } = this.props;
        createFamily(values).then((result) => {
            getUserFamilies();
            this.toggleModal('createFamilyModal')
            this.stopLoader()
        }).catch((err) => {
            this.stopLoader()
        })
    }

    requestAction = (familyUserId,action) => {

    }

    onCloseMemberModal = () => {
        this.setState({ familyUsers: null, familyId: null })
    }

    toggleModal = (modal,familyId=null) => {
        const state = this.state;
        if(modal == 'addFamilyMemberModal' || modal == 'memberRequestModal') {
            state['familyId'] = familyId
        }
        state[`${modal}`] = !state[`${modal}`]
        this.setState(state)
    }

    render() {
        const { user, families } = this.props;
        const { attributes,memberRequestModal, familyUsers, loader, familyId, createFamilyModal, joinFamilyModal, addFamilyMemberModal } = this.state;

        return (
            <React.Fragment>
                <Spin spinning={loader}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <ProfileCard user={user} attributes={attributes} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FamiliesCard toggleModal={this.toggleModal} families={families} viewMembers={this.loadFamilyMembers} leaveFamily={this.leaveFamily} />
                            {familyUsers && <MembersList family={find(families, ['id', familyId])} users={familyUsers} onCloseMemberModal={this.onCloseMemberModal} />}
                            <CreateFamilyModal modalVisible={createFamilyModal} toggleModal={this.toggleModal} createFamily={this.createFamily} />
                            <JoinFamilyModal modalVisible={joinFamilyModal} toggleModal={this.toggleModal} joinFamily={this.joinFamily} />
                            <AddFamilyMemberModal familyId = {familyId} modalVisible={addFamilyMemberModal} toggleModal={this.toggleModal} addMember={this.addMember} />
                            <MemberRequestModal familyId = {familyId} modalVisible={memberRequestModal} toggleModal={this.toggleModal} requestAction = {this.requestAction}/>
                        </Col>
                    </Row>
                </Spin>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
    families: state.auth.families
});

export default requireAuth(connect(mapStateToProps, {
    login, getUserFamilies, getFamilyUsers, leaveFamily, createFamily
})(ProfileScreen));