import React, { PureComponent } from 'react'
import { Form, Modal,Input, Icon,Button } from 'antd';

class CreateFamilyModal extends PureComponent {

    handleSubmit = e => {
        const { createFamily } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
            createFamily(values);
          }
        });
      };


    render() {
        const { getFieldDecorator } = this.props.form;
        const { toggleModal,modalVisible } = this.props;
        return (

            <Modal
                visible={modalVisible}
                footer={null}
                onCancel={()=>{toggleModal('createFamilyModal')}}
                title="Create a new family">

                <div onSubmit={this.handleSubmit}  className="families-card-container">
                    <Form >
                        <Form.Item label="Family name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please input family name !' }],
                            })(
                                <Input

                                    prefix={<Icon type="usergroup-add" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Family Name"
                                    size="large"
                                />,
                            )}
                        </Form.Item>
                        <Button type="primary" block htmlType="submit" className="btn-primary ant-btn ant-btn-primary">
                            Create Family
                        </Button>
                    </Form>
                </div>
            </Modal>
        )
    }
}

export default Form.create({ name: 'create_family' })(CreateFamilyModal);
