import get from 'lodash/get';
import { message } from "antd";
/**
 * Constants
 */
import * as TYPES from '../constants/actionTypes';
import * as APIS from '../constants/apiEndpoints';
import { FAMILY_CREATED_SUCCESS, FAMILY_JOIN_SUCCESS,MEMBER_ADDED_TO_FAMILY_SUCCESS,GET_PENDING_REQUEST_FAMILY_USERS } from '../constants/messages'

export const getFamilyUsers = familyId => (dispatch, getState, { api }) => {
    return new api.get(APIS.GET_FAMILY_USERS.replace(':familyId', familyId)).then(res => {
        return get(res, 'data.data.data');
    });
}

export const getFamilyPendingRequestMember = familyId => (dispatch, getState, { api }) => {
    
    return new api.get(APIS.GET_PENDING_REQUEST_FAMILY_USERS.replace(':familyId', familyId)).then(res => {
        return get(res, 'data.data.data');
    }).catch((error)=>{
        throw error;
    });
}

export const leaveFamily = familyId => (dispatch, getState, { api }) => {

    return new api.patch(APIS.LEAVE_FAMILY.replace(':familyId', familyId)).then(res => {
        return get(res, 'data.data');
    });
}

export const createFamily = (familyData) => (dispatch, getState, { api }) => {

    return api.post(APIS.CREATE_FAMILY, familyData)
        .then((result) => {
            message.success(FAMILY_CREATED_SUCCESS);
            return result
        })
        .catch((error) => {
            throw error
        })
}

export const actionFamilyRequest = (familyId,familyUserId,action=null) => (dispatch, getState, { api }) => {

    let  url = APIS.ACTION_ON_PENDING_REQUEST.replace(':familyId',familyId);
    url = url.replace(':familyUserId',familyUserId);
    url =`${url}?action=${action}`;

    return api.patch(url)
    .then((result) => {
        
        return result
    })
    .catch((error) => {
        throw error
    })
}

export const addMemberToFamily = (userId,familyId) => (dispatch, getState, { api }) => {
    let url = APIS.ADD_FAMILY_TO_MEMBER.replace(':userId', userId);
    url = url.replace(':familyId', familyId)
    return api.post(url)
        .then((result) => {
            message.success(MEMBER_ADDED_TO_FAMILY_SUCCESS);
            return result
        })
        .catch((error) => {
            throw error
        })
}

export const joinFamily = familyId => (dispatch, getState, { api }) => {

    return new api.patch(APIS.JOIN_FAMILY.replace(':familyId', familyId)).then(res => {
        message.success(FAMILY_JOIN_SUCCESS);
        return get(res, 'data.data');
    }).catch((error) => {
        throw error
    });
}

export const searchFamily = (query) => (dispatch, getState, { api }) => {

    let url = `${APIS.SEARCH_FAMILIES}${query}`
    return api.get(url)
        .then((result) => {
            return get(result, 'data.data')
        })
        .catch((error) => {
            throw error
        })
}

