import React, { Component } from 'react'
import { Card, Row, Col, Avatar } from 'antd';
import folderImage from './../assets/images/folder-2.png';
import { Link } from 'react-router-dom';
import * as ROUTES from './../constants/routes';

export default class CollectionItem extends Component {

    onClickCollection = () => {
        const { goToCollection, collection } = this.props;
        goToCollection(collection.id);
    }

    render() {
        const { collection,sharedCollection } = this.props;
        var url = ROUTES.COLLECTION.replace(':collectionId', collection.id);
        
        return (
            <Link to={url}>
                <Col span={8}>
                    <Card className="card-icon bg-light-blue card-shadow">
                        <div className="head-info" >
                            <img src={folderImage} className="icon-sec mr--15" alt="logo" /> {collection.name}
                        </div>
                        <div className="share-detail-sec">
                            <Avatar className="mr--10">A</Avatar> <Avatar>S</Avatar>
                        </div>
                        <img src={folderImage} className="icon-backdrop" alt="logo" />
                    </Card>
                </Col>
            </Link>
        )
    }
}
