import React, { Component } from 'react'
import { Modal,Tooltip } from 'antd';
import { connect } from "react-redux";
import { fetchCollectionUser, stopShareCollection } from './../actions/collectionActions';
import { List, Icon, Button, Select, Row, Popconfirm } from 'antd';
import get from 'lodash/get';


class CollectionMemberModal extends Component {

    state = {
        members: [],
        loading: false
    }
    
    componentWillMount() {
        let {isSharedFolder,visible} = this.props;
        console.log("componentWillReceiveProps CollectionMemberModal", this.props)
        if(!isSharedFolder) {
            this.getCollectionMembers(this.props);
        }
    }

    getCollectionMembers = (props) => {
        let { parentCollection, fetchCollectionUser } = props;
         console.log(" getCollectionMembers CollectionMemberModal", parentCollection)
        this.loading(true);
        fetchCollectionUser(parentCollection.id).then((members) => {
            this.setState({ members });
            this.loading(false)
        }).catch(() => {
            this.loading(false)
        })
    }

    stopSharingCollection = (userId) => {
        let { parentCollection, fetchCollectionUser,stopShareCollection } = this.props;
        let body = {
            user_id : userId,
            collection_id: get(parentCollection,'id')
        }
        this.loading(true);
        stopShareCollection(body)
        .then((result)=>{
            this.loading(false);
            this.getCollectionMembers(this.props);
        }).catch(()=>{
            this.loading(false);
        })
    }



    loading = (value) => this.setState({ loading: value });

    render() {
        const { parentCollection, visible, onClose } = this.props;
        const { members } = this.state;
        console.log("parentCollection", parentCollection);
        return (
            <Modal title="Collection Members" destroyOnClose={true} visible={visible} onCancel={onClose} footer={null} className="add-modal-container">
                <div className="families-card-container">
                    {/* <Card title="Family Members" className="families-card"> */}
                    <List
                        dataSource={members}
                        renderItem={user => (
                            <List.Item
                                key={user.id}
                            >
                                <List.Item.Meta
                                    title={`${user.first_name} ${user.last_name} @${user.username}`}
                                    description={(
                                        <>
                                            <p> <Icon rotate={90} theme="twoTone" type="phone" /> {user.phone} </p>
                                            <p> <Icon theme="twoTone" type="mail" /> {user.email}</p>
                                            {get(user, 'Share.subfolders_shared') == 1 ? (<p> {"Subfolders shared"} <Icon theme="twoTone" type="check-circle" twoToneColor="#52c41a" /> </p>)
                                                : (<p> {"Subfolders shared"} <Icon theme="twoTone" type="close-circle" twoToneColor="red" /> </p>)}
                                        </>
                                    )}
                                />

                                <div>
                                    <Tooltip title="Stop Sharing this collection">
                                        <Popconfirm
                                            title="Are you sure, you want to stop sharing this collection ?"
                                            okText="Yes"
                                            onConfirm={() => { this.stopSharingCollection(user.id) }}
                                            cancelText="No"
                                        >
                                            <Icon  type="stop" theme="twoTone" />
                                        </Popconfirm>
                                    </Tooltip>
                                </div>
                            </List.Item>
                        )}
                    ></List>
                    {/* </Card> */}
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, { fetchCollectionUser,stopShareCollection })(CollectionMemberModal);
