import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { Form, Modal, Spin, Icon, Button, Select } from 'antd';
import { searchUser } from '../../actions/userActions';
import { connect } from "react-redux";
import { addMemberToFamily } from '../../actions/familyActions';
const { Option } = Select;

let timeout;
let currentValue;


class AddFamilyMemberModal extends PureComponent {

    state = {
        data: [],
        value: undefined,
        loader:false
    }

    componentDidMount = () => {

    }

    loading=(loader)=>{this.setState({loader})}

    handleSubmit = (e) => {

        let { familyId, form, addMemberToFamily, toggleModal } = this.props;
        let { value } = this.state;
        e.preventDefault();
        
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.loading(true);
                addMemberToFamily(value, familyId).
                    then((result) => {
                        this.loading(false);
                        this.setState({
                            data: [],
                            value: null,
                        })
                        form.resetFields();
                        toggleModal('addFamilyMemberModal');

                    }).catch((error) => {
                        this.loading(false);
                    });
            }
        })
    }

    handleSearch = value => {
        if (value) {
            this.seachUser(value, data => this.setState({ data }));
        } else {
            this.setState({ data: [] });
        }
    };


    handleChange = value => {
        this.setState({ value });
    };

    seachUser = (value, callback) => {

        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }

        currentValue = value;
        const { searchUser } = this.props;

        function queryUserData() {

            const data = [];

            searchUser(value).then((result) => {
                result.map((val) => {
                    data.push({
                        value: val.id,
                        text: val.name
                    })
                })

                callback(data);

            }).catch((error) => {

            })
        }
        timeout = setTimeout(queryUserData, 100);
    }

    onCloseModal = () => {
        let { form, toggleModal } = this.props;
        form.resetFields();
        toggleModal('addFamilyMemberModal')
    }

    render() {

        let { modalVisible, toggleModal } = this.props;
        const { getFieldDecorator } = this.props.form;
        const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>);
        let {loader} = this.state;
        return (

            <Modal
                visible={modalVisible}
                footer={null}
                onCancel={() => { this.onCloseModal() }}
                title="Add a new family member">
                    <Spin spinning={loader}>
                <div onSubmit={this.handleSubmit} className="families-card-container">
                    <Form >
                        <Form.Item label="Select user">
                            {getFieldDecorator('familyId', {
                                rules: [{ required: true, message: 'Please select a family !' }],
                            })(
                                <Select
                                    showSearch
                                    value={this.state.value}
                                    placeholder={"seach by username"}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    style={{ width: '100%' }}
                                    onSearch={this.handleSearch}
                                    onChange={this.handleChange}
                                    notFoundContent={null}
                                >
                                    {options}
                                </Select>
                            )}
                        </Form.Item>
                        <Button type="primary" block htmlType="submit" className="btn-primary ant-btn ant-btn-primary">
                            Add member to family
                        </Button>
                    </Form>
                </div>
                </Spin>
            </Modal>
        )
    }
}

const AddFamilyMemberWrapper = Form.create({ name: 'add_family' })(AddFamilyMemberModal);

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, { searchUser, addMemberToFamily })(AddFamilyMemberWrapper);
