import React, { Component } from 'react';
import { Avatar, Row, Col, List, Tag, Icon, Popconfirm, Tooltip, Spin } from 'antd';
import graphicBoy from './../assets/images/questions-bg.jpg';
import find from 'lodash/find'
import QuestionDetailCard from '../components/QuestionDetailCard';
import { deleteQuestion } from '../actions/questionAction'
import { fetchCollectionById } from '../actions/collectionActions'
import { connect } from "react-redux";
import get from 'lodash/get';

class MyQuestions extends Component {

  state = {
    questionDetailModal: false,
    selectedQuestion: null,
    note: null,
    loader: false
  }

  loading = (loader) => this.setState({ loader })

  viewQuestionDetails = (selectedQuestion, note) => {
    this.setState({ selectedQuestion, questionDetailModal: true, note });
  }

  toggleQuestiondetailModal = () => {
    let { questionDetailModal } = this.state;
    this.setState({ questionDetailModal: !questionDetailModal })
  }

  deleteQuestion = (questionId) => {
    this.loading(true);
    let { deleteQuestion, parentCollection, fetchCollectionById } = this.props;

    deleteQuestion(questionId)
      .then((resut) => {

        fetchCollectionById(get(parentCollection, 'id'));
        this.loading(false);
      }).catch((error) => {
        this.loading(false);
      })
  }

  editQuestion = (question) => {
    this.toggleQuestiondetailModal();
    this.props.editQuestion(question)
    
  }

  render() {
    const { questions, notes,isSharedFolder,isCollectionEditable } = this.props;
    let { selectedQuestion, questionDetailModal, note, loader } = this.state;

    return (
      <Spin spinning={loader}>
        <div className="question-sec mt--20">

          <List
            className="questions-item"
            grid={{
              gutter: 12,
              xs: 2,
              sm: 2,
              md: 2,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            dataSource={questions}
            renderItem={question => {
              let note = get(find(notes, ['id', question.note_id]),'title',"");

              return (
                <List.Item>
                  <List.Item.Meta />
                  <Row>
                    <Col span={2}>
                      <a><Avatar shape="square" className="list-icon icon-success">Q</Avatar></a>
                    </Col>

                    <Col span={12}>
                      <div>{question.title}</div>
                      <div><Tag color="orange"> {note} </Tag></div>
                    </Col>

                    <Col span={10}>
                      <div><a onClick={() => { this.viewQuestionDetails(question, note) }}>View details</a></div>
                      {(!isSharedFolder || isCollectionEditable) && <div>
                        <Tooltip title="Delete this question">
                          <Popconfirm
                            title="Are you sure, you want to delete this question ?"
                            okText="Yes"
                            onConfirm={() => { this.deleteQuestion(get(question, 'id')) }}
                            cancelText="No"
                          >
                            <Icon type="delete" theme="twoTone" />
                          </Popconfirm>
                        </Tooltip>
                      </div>}
                    </Col>
                  </Row>
                </List.Item>
              )
            }
            }
          />

          <QuestionDetailCard isSharedFolder = {isSharedFolder} editQuestion = {this.editQuestion} visible={questionDetailModal} note={note} question={selectedQuestion} toggleQuestiondetailModal={this.toggleQuestiondetailModal} />

          <div className="img-graphic">
            {/* <img src={graphicBoy} className="img-graphic-item" alt="logo" /> */}
          </div>
        </div>
      </Spin>
    )
  }

}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  deleteQuestion,
  fetchCollectionById
})(MyQuestions);
