import React, { Component } from 'react'
import { connect } from "react-redux";
import MyCollection from '../components/MyCollection';
import MyNotes from '../components/MyNotes';
import MyQuestions from '../components/MyQuestions';
import { fetchCollectionById, fetchSharedCollection } from './../actions/collectionActions';
import requireAuth from '../hocs/requireAuth';
import { Empty, Button, Icon, Collapse } from 'antd';
import AddCollectionModal from '../components/AddCollectionModal';
import ShareCollectionModal from '../components/ShareCollectionModal';
import CollectionMemberModal from '../components/CollectionMemberModal';
import AddNoteDrawer from '../components/AddNoteDrawer';
import AddActionBar from '../components/AddActionBar';
import AddQuestionDrawer from '../components/AddQuestionDrawer'
import CreateQuiz from '../components/CreateQuiz'
import get from 'lodash/get';
import find from 'lodash/find';
import { createQuiz } from '../actions/quizAction';
import * as ROUTES from '../constants/routes';

const { Panel } = Collapse;

const customPanelStyle = {
  background: 'white',
  borderRadius: 4,
  marginBottom: 24,
  border: 10,
  overflow: 'hidden',
  showShareCollectionModal: false,
  sharedCollection: false

};

class CollectionScreen extends Component {

  state = {
    loading: false,
    showAddCollectionModal: false,
    showAddNote: false,
    isNoteUpdate: false,
    noteDetails: null,
    isRootCollection: false,
    isSharedFolder: false,
    showCollection: true,
    isCollectionEditable: true,
    showAddQuestion: false,
    isQuestionUpdate: false,
    questionForUpdate: null,
    quizModal: false
  }


  /**
   * LifeCylce Methods
   */
  componentDidMount() {

    const { match, fetchCollectionById, selectedCollection, sharedCollection, rootCollectionId } = this.props;
    const { collectionId } = match.params;


    if (!selectedCollection || selectedCollection.id !== collectionId) {

      fetchCollectionById(collectionId);
    }

    if (rootCollectionId == get(selectedCollection, 'id')) {
      this.fetchSharedCollection();
    }

  }

  componentWillReceiveProps(nextProps) {

    const { fetchCollectionById, selectedCollection, rootCollectionId, sharedCollection } = nextProps;
    const collectionId = nextProps.match.params.collectionId;

    if (!collectionId || collectionId == rootCollectionId) {
      this.setState({ isRootCollection: true })
    } else {
      this.setState({ isRootCollection: false })
    }

    let selectedSharedCollection = find(sharedCollection, ['id', parseInt(collectionId)]);

    if (selectedSharedCollection) {

      let obj = {};
      obj['isSharedFolder'] = true;
      obj['showCollection'] = (get(selectedSharedCollection, 'Share.subfolders_shared')) ? true : false;
      obj['isCollectionEditable'] = (get(selectedSharedCollection, 'Share.permission') == 2) ? true : false;
      this.setState(obj);

    } else {
      this.setState({ isSharedFolder: false, showCollection: true, isCollectionEditable: true })
    }

    if (collectionId && selectedCollection.id != collectionId) {

      fetchCollectionById(collectionId);

    }
  }

  fetchSharedCollection = () => {

    let { fetchSharedCollection } = this.props;
    fetchSharedCollection().then((result) => {

      this.setState({ sharedCollection: result.data })
    }).catch((error) => {
      console.log("error", error);
    })

  }

  fetchCollectionDetails = (collectionId = undefined) => {
    const { fetchCollectionById, history } = this.props;

    this.setState({ loading: true });
    fetchCollectionById(collectionId).then(res => {
      this.setState({ loading: false });
    }).catch(error => {
      this.setState({ loading: false });
    })
  }


  toggleCollectionModal = () => {
    const { showAddCollectionModal } = this.state;
    this.setState({ showAddCollectionModal: !showAddCollectionModal });
  }

  toggleShareCollectionModal = () => {
    const { showShareCollectionModal } = this.state;
    this.setState({ showShareCollectionModal: !showShareCollectionModal });
  }

  toggleCollectionMemberModal = () => {
    const { showCollectionMemberModal } = this.state;
    this.setState({ showCollectionMemberModal: !showCollectionMemberModal });
  }

  toggleNoteDrawer = (refreshCollection = false) => {
    if (refreshCollection) {
      const { match, fetchCollectionById } = this.props;
      const { collectionId } = match.params;
      fetchCollectionById(collectionId);
    }
    const { showAddNote } = this.state;
    this.setState({ showAddNote: !showAddNote, isNoteUpdate: false, noteDetails: null });
  }

  toggleQuestionDrawer = (refreshCollection = false) => {
    if (refreshCollection) {
      const { match, fetchCollectionById } = this.props;
      const { collectionId } = match.params;
      fetchCollectionById(collectionId);
    }

    const { showAddQuestion } = this.state;
    this.setState({ showAddQuestion: !showAddQuestion, isQuestionUpdate: false, questionForUpdate: null });

  }

  toggleQuizContainer = () => {
    let { quizModal } = this.state;
    this.setState({ quizModal: !quizModal });
  }

  editNotes = (noteDetails) => {
    this.setState({ showAddNote: true, isNoteUpdate: true, noteDetails: noteDetails });
  }

  editQuestion = (question) => {
    this.toggleQuestionDrawer();
    this.setState({ isQuestionUpdate: true, questionForUpdate: question })
  }

  getAddQuestionButton = () => {
    let {isSharedFolder,isCollectionEditable} = this.state;

    if(!isSharedFolder || isCollectionEditable) {

      return (
        <div style={{ marginTop: -40 }}>
          <Button onClick={this.toggleQuestionDrawer} type="primary" icon="file-add" >Add More Questions</Button>
        </div>
      )

    }
   
  }


  onSubmitCreateQuiz = (values) => {

    let { createQuiz } = this.props;

    createQuiz(values)
      .then((result) => {
        console.log("result====>",get(result,'id'));
        this.props.history.push(ROUTES.QUIZ.replace(':quizId',get(result,'id')));

      }).catch((error) => {
        console.log("error", error);
      });

  }

  render() {
    const { rootCollection, selectedCollection, sharedCollection } = this.props;
    const { isCollectionEditable, showCollection, isSharedFolder,
      showCollectionMemberModal, showShareCollectionModal, isQuestionUpdate, questionForUpdate,
      showAddCollectionModal, quizModal, showAddNote, isNoteUpdate, noteDetails, isRootCollection, showAddQuestion } = this.state;

    if (!selectedCollection) {
      return (
        <div className="dashboard-panel">
          <Empty />
        </div>
      )
    }

    return (
      <div className="dashboard-panel">

        <AddActionBar 
        rootCollection={rootCollection} selectedCollection={selectedCollection} 
        isSharedFolder={isSharedFolder} addCollection={this.toggleCollectionModal} shareCollection={this.toggleShareCollectionModal} 
        showCollectionMembers={this.toggleCollectionMemberModal} addNote={this.toggleNoteDrawer} createQuiz={this.toggleQuizContainer}  isCollectionEditable = {isCollectionEditable}/>
        <div className="collection-title">{selectedCollection.name}</div>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => <Icon style={{ fontSize: '20px' }} type="caret-right" rotate={isActive ? 90 : 0} />}
        >
          {showCollection && <Panel header="Collections" key="1" style={customPanelStyle}>
            <MyCollection sharedCollection={false} goToCollection={this.fetchCollectionDetails} collections={selectedCollection.subCollections} />
          </Panel>
          }
          {(isRootCollection && sharedCollection.length) && (
            <Panel header="Shared Collections" key="2" style={customPanelStyle}>
              <MyCollection sharedCollection={true} goToCollection={this.fetchCollectionDetails} collections={sharedCollection} />
            </Panel>
          )}
          <Panel header={(<h4 className="title primary-title">Notes</h4>)} head key="3" style={customPanelStyle}>
            <MyNotes isCollectionEditable={isCollectionEditable} editNotes={this.editNotes} notes={selectedCollection.notes} />
          </Panel>
          <Panel extra={this.getAddQuestionButton()} header = { <h4 className="title primary-title"> Questions </h4> } key="4" style={customPanelStyle}>
            <MyQuestions isCollectionEditable = { isCollectionEditable } isSharedFolder = {isSharedFolder} editQuestion = {this.editQuestion} fetchCollectionDetails = {this.fetchCollectionDetails} questions = {selectedCollection.questions} notes = {selectedCollection.notes} parentCollection = {selectedCollection} />
          </Panel>
        </Collapse>

        {/* <div className="add-button-container"><Button type="primary" onClick={this.toggleNoteDrawer} shape="circle" icon="plus" size="large" /></div> */}
        <AddCollectionModal visible={showAddCollectionModal} onClose={this.toggleCollectionModal} parentCollection={selectedCollection} />
        <ShareCollectionModal visible={showShareCollectionModal} onClose={this.toggleShareCollectionModal} parentCollection={selectedCollection} />
        {showCollectionMemberModal && <CollectionMemberModal isSharedFolder={isSharedFolder} visible={showCollectionMemberModal} onClose={this.toggleCollectionMemberModal} parentCollection={selectedCollection} />}
        <AddNoteDrawer isNoteUpdate={isNoteUpdate} noteDetails={noteDetails} visible={showAddNote} parentCollection={selectedCollection} onClose={this.toggleNoteDrawer} />
        <AddQuestionDrawer isQuestionUpdate={isQuestionUpdate} questionForUpdate={questionForUpdate} notes={selectedCollection.notes} visible={showAddQuestion} onClose={this.toggleQuestionDrawer} parentCollection={selectedCollection} />
        <CreateQuiz visible={quizModal} onClose={this.toggleQuizContainer} parentCollection={selectedCollection} onSubmitCreateQuiz={this.onSubmitCreateQuiz} />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
  rootCollectionId: get(state, 'auth.user.rootCollection.id'),
  rootCollection: get(state, 'auth.user.rootCollection'),
  selectedCollection: state.collection.selectedCollection,
  sharedCollection: state.collection.sharedCollection
});

export default requireAuth(connect(mapStateToProps, {
  fetchCollectionById,
  fetchSharedCollection,
  createQuiz
})(CollectionScreen));
