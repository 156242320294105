import * as TYPES from '../constants/actionTypes';

export default (state = {
    quizzes : [],
    quizzesTaken : null,
    averagePercentage : null,
    latestNotes : [],
    notesAdded : 0,
    notesRead : [],
    totalNotesRead : 0,
    totalReadTime : 0
}, action) => {
    
    switch(action.type) {
        
        case TYPES.FETCH_SUMMARY :
            return {...state, ...action.payload}     
        default:
            return state
        
    }
}   