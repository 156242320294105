import get from 'lodash/get';
import { message } from "antd";
import * as TYPES from './../constants/actionTypes';
import * as APIS from './../constants/apiEndpoints';
import { QUIZ_CREATED_SUCCESS } from '../constants/messages'


export const createQuiz = payload => (dispatch, getState, { api }) => {

    return new api.post(APIS.CREATE_NEW_QUIZ, payload)
        .then(res => {
            message.success(QUIZ_CREATED_SUCCESS);
            return get(res, 'data.data');

        }).catch((error) => {
            throw error;
        });
}

export const getQuiz = (quizId) => (dispatch, getState, { api }) => {

    return new api.get(APIS.GET_NEW_QUIZ.replace(':quizId', quizId)).
        then((result) => {
            return get(result, 'data.data');
        }).catch((error) => {
            throw error;

        })

}

export const getQuizResult = (quizId) => (dispatch, getState, { api }) => {

    return new api.get(APIS.QUIZ_RESULT.replace(':quizId', quizId)).
        then((result) => {
            return get(result, 'data.data');
        }).catch((error) => {
            throw error;

        })

}

export const submitQuiz = (quizId, payload) => (dispatch, getState, { api }) => {

    let url = APIS.SUBMIT_QUIZ_RESPONSE.replace(':quizId', quizId);
    return new api.post(url, payload)
        .then((result) => {
            return get(result, 'data.data');
        }).catch((error) => {
            throw error;
        })
}

export const getAllUserQuiz = () => (dispatch, getState, { api }) => {

    return api.get(APIS.GET_ALL_QUIZ)
        .then((result) => {
            return get(result, 'data.data');
        }).catch((error) => {
            throw error;
        })

}

export const reAttemptQuiz = (quizId) => (dispatch, getState, { api }) => {

    return api.patch(APIS.REATTEMPT_QUIZ.replace(':quizId',quizId))
        .then((result) => {
            return 
        }).catch((error) => {
            console.log("====>",error);
            throw error;
        })
}
