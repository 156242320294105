import React, { Component, Fragment } from 'react'
import { Layout, Menu, Input, Avatar, Badge } from 'antd';
import Profile from './Profile';
import { matchPath } from "react-router";
import * as ROUTES from './../constants/routes';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { logout } from './../actions/authActions';
const { Header } = Layout;
const Search = Input.Search;

class Navbar extends Component {

  showSidebar = () => {
    const { location } = this.props;
    if (
      matchPath(
        location.pathname,
        { path: ROUTES.LOGIN, exact: true }
      ) || matchPath(
        location.pathname,
        { path: ROUTES.SIGNUP, exact: true })
    ) {
      return false;
    };

    return true;
  }

  logout = () => {
    const { logout } = this.props;
    logout();
  }

  goToProfile = () => {
    const { history } = this.props;
    history.push(ROUTES.PROFILE);
  }

  render() {
    const { user } = this.props;

    if (!this.showSidebar() || !user) return (<Fragment></Fragment>);
    return (
      <Header className="header-section secondary-shadow">
        <div></div>
        <div style={{ backgroundColor: "red" }}>
          <Menu
            className="menu-section"
            mode="horizontal"
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1" className="profile-item-menu">
              <Profile name={user.name} logout={this.logout} goToProfile={this.goToProfile} />
            </Menu.Item>
          </Menu>
        </div>
        {/* <Search
          placeholder="Search Keyword"
          onSearch={value => console.log(value)}
          style={{ width: 250 }}
          className="search-input"
        /> */}


      </Header>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user
});

export default withRouter(connect(mapStateToProps,
  { logout }
)(Navbar));