import React, { Component } from 'react';
import { Card, Icon } from 'antd';
import { List, Tag } from 'antd';


const data = [
    {
        title: 'Teacher Meeting',
    },
    {
        title: 'Campus Tour',
    },
    {
        title: 'Parent Meeting',
    },
    {
        title: 'Guest Lacture',
    },
];

export default class TodayTask extends Component {
    render() {
        return (
            <Card className="card-list-task">
                <div className="card-header-sec">
                    <div className="title">
                        <Icon type="calendar" />Today Task
                    </div>
                </div>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={  <Tag className="circle-tag">10 AM</Tag>}
                                title={<a href="https://ant.design">{item.title}</a>}
                                description="Ant Design, a design language"
                            />
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}
