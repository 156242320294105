import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Button, Checkbox, notification } from 'antd';
import { connect } from "react-redux";
import { signUp } from './../actions/authActions'
import get from "lodash/get";
import head from "lodash/head";

class SignUpForm extends React.Component {

  state = {
    errors: {}
  }

  handleSubmit = e => {

    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        const { signUp, history, startLoading, stopLoading } = this.props;

        startLoading();

        signUp(values).then((result) => {
          stopLoading();
          notification.open({
            message: "Welcome to study planner",
            icon: <Icon type="smile" style={{ color: '#108ee9' }} />,
          })
        }).catch((error) => {
          stopLoading();
          let errors = get(error, 'errors', []);
          this.setState({ errors });

        })
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    // alert("hii")
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { errors } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} className="signup-form">
        <Form.Item validateStatus={(get(errors, 'first_name')) ? 'error' : ''} help={head(get(errors, 'first_name'))} >
          {getFieldDecorator('first_name', {
            rules: [{ required: true, message: 'Please input your first name!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="First Name"
              size="large"
            />,
          )}
        </Form.Item>

        <Form.Item validateStatus={(get(errors, 'last_name')) ? 'error' : ''} help={head(get(errors, 'last_name'))} >
          {getFieldDecorator('last_name', {
            rules: [{ required: true, message: 'Please input last name!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Last Name"
              size="large"
            />,
          )}
        </Form.Item>

        <Form.Item validateStatus={(get(errors, 'email')) ? 'error' : ''} help={head(get(errors, 'email'))} >
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="email"
              size="large"
            />,
          )}
        </Form.Item>

        <Form.Item validateStatus={(get(errors, 'phone')) ? 'error' : ''} help={head(get(errors, 'phone'))} >
          {getFieldDecorator('phone', {
            rules: [{ required: true, message: 'Please input your Phone!' }],
          })(
            <Input
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Phone"
              size="large"
            />,
          )}
        </Form.Item>

        {/* <Form.Item validateStatus={"warning"} help={"Prime between 8 & 12"}> */}
        <Form.Item validateStatus={(get(errors, 'password')) ? 'error' : ''} help={head(get(errors, 'password'))} >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              size="large"
              placeholder="Create Password"
            />,
          )}
        </Form.Item>

        <Form.Item validateStatus={(get(errors, 'confirm_password')) ? 'error' : ''} help={head(get(errors, 'confirm_password'))} >
          {getFieldDecorator('confirm_password', {
            rules: [
              { required: true, message: 'Please input confirm password!' },
              {
                validator: this.compareToFirstPassword,
              }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              size="large"
              placeholder="confirm Password"
            />,
          )}
        </Form.Item>


        <Form.Item>
          {getFieldDecorator('remember', {
            initialValue: true,
          })(<Checkbox className="link-gray"><small className="xs-small"> I accept the <a href="/"> Terms of Use </a>& <a href="/"> Privacy Policy</a></small></Checkbox>)}
          <Button type="primary" block htmlType="submit" className="btn-primary">
            Sign up
          </Button>
          <div className="text-center">
            <span className="text-light"> Already a member?</span> <Link to="/login" className="link-primary">login here</Link>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedSignUpform = Form.create({ name: 'sign_up_form' })(SignUpForm);

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
  signUp
})(WrappedSignUpform);