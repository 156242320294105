import React, { Component } from 'react';
import { Calendar , Card, Icon } from 'antd';

function onPanelChange(value, mode) {
    console.log(value, mode);
  }
  
export default class LatestNotes extends Component {
    render() {
        return (
            <Card className="calendar-section">
                <div className="card-header-sec">
                    <div className="title">
                    <Icon type="calendar" /> Events Calendar
                     </div>
                </div>
                <Calendar mode="month" fullscreen={false} onPanelChange={onPanelChange} />
            </Card>
        );
    }
}

