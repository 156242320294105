// Auth Actions
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// User Actions
export const USER_FAMILIES_FETCHED = "USER_FAMILIES_FETCHED";

// Summary Actions
export const FETCH_SUMMARY = "FETCH_SUMMARY";


// Fetch Collection
export const FETCH_COLLECTION = "FETCH_COLLECTION";
export const COLLECTION_CREATED = "COLLECTION_CREATED";
export const FETCH_SHARED_COLLECTION = "FETCH_SHARED_COLLECTION";


// Fetch Collection
export const NOTE_CREATED = "NOTE_CREATED";
