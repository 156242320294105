import React, { PureComponent } from 'react'
import { Icon, message, List, Card, Modal, Button } from 'antd';
import get from 'lodash/get'
import { connect } from "react-redux";
import { getFamilyPendingRequestMember, actionFamilyRequest } from '../../actions/familyActions'
import { getUserFamilies} from '../../actions/userActions'
import { APPROVE_REQUEST, REJECT_REQUEST } from '../../constants/messages'

class MemberRequestModal extends PureComponent {

    state = {
        users: []
    }

    componentWillReceiveProps(nextProps) {
        let { familyId } = this.props;

        if (nextProps.familyId != familyId && nextProps.familyId) {
            this.getPendingRequest(nextProps.familyId);
        }
    }

    getPendingRequest = (familyId) => {

        let { getFamilyPendingRequestMember } = this.props;

        getFamilyPendingRequestMember(familyId)
            .then((users) => {
                this.setState({ users });
            })
            .catch((error) => {

            })
    }

    handleSubmit = (familyUserId, action) => {
        let { familyId, actionFamilyRequest,toggleModal,getUserFamilies } = this.props;

        actionFamilyRequest(familyId, familyUserId, action)
            .then((result) => {
                if (action == 1) {
                    message.success(APPROVE_REQUEST);
                } else {
                    message.success(REJECT_REQUEST);
                }
                toggleModal('memberRequestModal',null);
                getUserFamilies();
            }).catch((error) => {
                console.log("error", error);
            })
    }

    render() {

        const { toggleModal, modalVisible } = this.props;
        let { users } = this.state;
        return (
            <Modal
                title={"User Requests to join family"}
                visible={modalVisible}
                onCancelText="close"
                onCancel={() => { toggleModal('memberRequestModal') }}
            >
                <div className="families-card-container">
                    <Card className="families-card">
                        <List
                            dataSource={users}
                            renderItem={user => (
                                <List.Item
                                    key={user.id}
                                >
                                    <List.Item.Meta
                                        title={`${user.first_name} ${user.last_name} @${user.username}`}
                                        description={(
                                            <>
                                                <p> <Icon rotate={90} theme="twoTone" type="phone" /> {user.phone} </p>
                                                <p> <Icon theme="twoTone" type="mail" /> {user.email} </p>

                                            </>
                                        )}
                                    />
                                    <div>
                                        <Button onClick={() => { this.handleSubmit(user.FamilyUser.id, 1) }} type="primary" style={{ margin: 10 }}  >Accept</Button>
                                        <Button onClick={() => { this.handleSubmit(user.FamilyUser.id, -1) }} type="danger" style={{ margin: 10 }}>Reject</Button>
                                    </div>
                                </List.Item>
                            )}
                        ></List>
                    </Card>
                </div>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, { getUserFamilies,getFamilyPendingRequestMember, actionFamilyRequest })(MemberRequestModal);

