import React, { PureComponent } from 'react'
import { Card,Button } from 'antd';
import FamilyInfo from './FamilyInfo';

export default class FamiliesCard extends PureComponent {
    
    renderHeaderActions = () => {
        const {toggleModal} = this.props;
        return (
            <>
                <Button type="primary"className="btn-blue ant-btn ant-btn ant-btn-secondary" icon="plus" onClick={()=>{toggleModal('createFamilyModal')}}>
                    Create Family
                </Button>
                
                <Button type="primary" className="btn-blue ant-btn ant-btn ant-btn-secondary" icon="usergroup-add" onClick={()=>{toggleModal('joinFamilyModal')}}>
                    Join Family
                </Button>
                
            </>
        )
    } 

    render() {
        const { families, viewMembers, leaveFamily,toggleModal } = this.props;

        return (
            <div className="families-card-container">
                <Card title="Your Families" className="families-card" extra={this.renderHeaderActions()}>
                    <FamilyInfo toggleModal={toggleModal} families={families} viewMembers={viewMembers} leaveFamily={leaveFamily} />
                </Card>
            </div>
        )
    }
}
