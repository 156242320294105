import React, { Component } from 'react'
import { Layout, Card, Row, Col } from 'antd';
import HeaderBarChart from './../components/HeaderBarChart';
import StatusCard from './../components/StatusCard';
import QuizScore from './../components/QuizScore';
import TodayTask from '../components/TodayTask';
import LatestNotes from '../components/LatestNotes';
import RecentComments from '../components/RecentComments';
import RecentQuizes from '../components/RecentQuizes';
import EventCalendar from '../components/EventCalendar';
import requireAuth from '../hocs/requireAuth';
import { connect } from "react-redux";
import { fetchSummary } from '../actions/summaryActions';

const { Content } = Layout;

class HomeScreen extends Component {

  state = {
    loading : false,
    days : 60
  }

  componentDidMount() {
    const { fetchSummary } = this.props;
    const { days } = this.state;

    this.setState({loading : true});

    fetchSummary(days).then(res => {
      this.setState({loading : false});
    }).catch(res => {
      this.setState({loading : false});
    })
  }

  render() {
    const { summary } = this.props;
    return (
      <Content style={{ margin: '', overflow: 'initial' }}>
        <Card className="title-bar-section">
          <Row type="flex" justify="space-between" align="middle">
            <Col xs={24} sm={12} >
              <h2 className="title mb--0"><small className="text-uppercase">Dashboard</small></h2>
              <small>Account Overview</small>
            </Col>
            <Col xs={24} sm={12}>
              <div className="chart-header-sec">
                <HeaderBarChart />
              </div>
            </Col>
          </Row>
        </Card>
        <div className="padding-md">
          <StatusCard summary = {summary} />
          <div className="quiz-tasksection">
            <Row gutter={15} type="flex" justify="space-between">
              <Col xs={24} sm={16} >
                <QuizScore />
              </Col>
              <Col xs={24} sm={8} >
                <TodayTask />
              </Col>
            </Row>
          </div>

          <div className="event-section mt--20">
            <Row gutter={15} type="flex" justify="space-between">
              <Col xs={24} sm={16} >
                <LatestNotes notes={summary.latestNotes} />
              </Col>
              {/* <Col xs={24} sm={8} >
                <RecentComments />
              </Col> */}
            </Row>
          </div>

          <div className="quizes-event-calendar mt--20">
            <Row gutter={15} type="flex" justify="space-between">
              <Col xs={24} sm={24} >
                <RecentQuizes quizzes={summary.quizzes} />
              </Col>
            
            </Row>
          </div>
        </div>
      </Content>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedIn : state.auth.loggedIn,
  user : state.auth.user,
  summary : state.summary
});

export default requireAuth(connect(mapStateToProps, 
  { fetchSummary }
)(HomeScreen));