import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';

import get from 'lodash/get';

import * as ROUTES from './../constants/routes';

export default ChildComponnent => {

  class RequireAuth extends Component {
    render() {
      const { loggedIn } = this.props;

      if(!loggedIn){
        return <Redirect to={ROUTES.LOGIN} />;
      }
      else {
        return <ChildComponnent {...this.props} />;
      }
    }
  }

  const mapStateToProps = (state) => ({
    loggedIn : get(state, 'auth.loggedIn')
  });
  
  return connect(
    mapStateToProps,
    { }
  )(RequireAuth);
};