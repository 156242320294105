import { combineReducers } from 'redux';

import authReducer from './authReducer';
import summaryReducer from './summaryReducer';
import collectionReduer from './collectionReduer';

export default combineReducers({
  auth: authReducer,
  summary : summaryReducer,
  collection :collectionReduer
});
