import React, { Component } from 'react';
// import {
//     BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
// } from 'recharts';
import {
    BarChart, Bar
} from 'recharts';

const data = [
    {
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
    {
        name: 'Page H', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page I', uv: 3520, pv: 4000, amt: 2100,
    },
];

export default class HeaderBarChart extends Component {
    render() {
        return (
            <div className="fx bar-chart-sec">
                <div className="ml-auto mr--20">
                    <h4 className="title mb--0"> Text sec</h4>
                    <div className="status-txt">$40, 00</div>
                </div>
                <BarChart width={100} height={50} data={data}>
                    <Bar barSize={50} dataKey="uv" fill="#CCCCCC" />
                </BarChart>
            </div>
        )
    }
}
