import React from 'react';
import { Drawer, Popconfirm, message, Spin, List, Modal, Upload, Form, Button, Col, Row, Input, Select, DatePicker, Icon } from 'antd';
import { connect } from "react-redux";
import { addNewNote, updateNote, unlinkNoteLink } from './../actions/noteActions';
import { uploadImage, unlinkMedia } from './../actions/mediaAction';
import get from 'lodash/get';
import map from 'lodash/map';
import remove from 'lodash/remove';
const { Option } = Select;
const children = [];
let id = 0;
const { confirm } = Modal;

class DrawerForm extends React.Component {

    state = {
        previewVisible: false,
        previewImage: null,
        fileList: [],
        linkList: [],
        images: [],
        event: null,
        loader: false,
        noteUpdate: false

    }

    componentWillReceiveProps(nextProps) {

        let { isNoteUpdate, noteDetails } = nextProps;
        let { noteUpdate } = this.state;



        if (isNoteUpdate && !noteUpdate) {

            if (get(nextProps, 'noteDetails.id', null) != get(this.props, 'noteDetails.id', null)) {
                let fileList = map(get(noteDetails, 'media'), (media) => {
                    return {
                        uid: get(media, 'id'),
                        name: 'image.png',
                        status: 'done',
                        url: get(media, 'url'),
                    }
                })

                let linkList = get(noteDetails, 'links')
                this.setState({ fileList, linkList, noteUpdate: true });
            }
        }

    }

    startLoading = () => {
        this.setState({ loader: true });
    }

    stopLoading = () => {
        this.setState({ loader: false });
    }

    unlinkMedia = (mediaUuid) => {
        this.startLoading();
        let { unlinkMedia, noteDetails } = this.props;

        unlinkMedia(mediaUuid)
            .then((result) => {
                this.stopLoading();
                return result;
            }).catch((error) => {
                this.stopLoading();
            })
    }

    unlinkNoteLink = (linkId) => {
        this.startLoading();
        let { unlinkNoteLink, noteDetails } = this.props;

        unlinkNoteLink(get(noteDetails, 'id'), linkId).then(() => {
            let { linkList } = this.state;
            remove(linkList, ['id', linkId]);
            this.setState({ linkList })
            this.stopLoading();
        }).catch(() => {
            this.stopLoading();
        })

    }

    onSubmit = event => {
        event.preventDefault();
        const { addNewNote, onClose, form, isNoteUpdate } = this.props;

        let { images } = this.state;

        form.validateFields((err, values) => {
            if (!err) {
                this.startLoading();
                values['images'] = images;
                // let links =  values['links'];
                // remove(links,null);
                // values['links']=links;
                if (!isNoteUpdate) {
                    addNewNote(values).then(res => {
                        this.stopLoading();
                        onClose(true);
                        form.resetFields();
                        id = 0;
                    }).catch(err => {
                        this.stopLoading();
                    });
                } else {

                    let { noteDetails, updateNote } = this.props;
                    updateNote(get(noteDetails, 'id'), values).then(res => {
                        this.stopLoading();
                        onClose(true);
                        id = 0;
                        form.resetFields();
                    }).catch(err => {
                        this.stopLoading();
                    });
                }
            }
        });
    }

    handleImagePreview = (file) => {

        this.setState({
            previewImage: file.url,
            previewVisible: true
        });

    }

    removeImage = async (index, index2) => {
        console.log("index", index, index2);
        await this.setState({ event: 'delete' });
    }

    deleteImage = async (fileObj) => {
        let fileUuid = get(fileObj, 'file.uid');
        var { fileList, images } = this.state;

        if (images.indexOf(fileUuid) == -1) {
            await this.unlinkMedia(fileUuid)
        } else {
            remove(images, (uuid) => {
                return fileUuid == uuid
            })
        }

        remove(fileList, (Obj) => {
            return fileUuid == Obj.uid
        })


        this.setState({ fileList, images, event: null })

    }

    onImageUpload = async (fileObj) => {

        let { event } = this.state;

        if (event == 'delete') {
            confirm({
                title: 'Do you Want to delete this Image ?',
                onOk: () => {
                    this.deleteImage(fileObj);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });

        } else {

            const { uploadImage } = this.props;

            uploadImage(fileObj, 1)
                .then((result) => {

                    var { fileList, images } = this.state;

                    fileList.push({
                        uid: get(result, 'id'),
                        name: 'image.png',
                        status: 'done',
                        url: get(result, 'url'),
                    });

                    images.push(get(result, 'id'));

                    this.setState({ fileList, images });
                })
                .catch((error) => {

                });
        }
    }

    handlePreviewCancel = () => {
        this.setState({
            previewVisible: false,
            previewImage: null
        })
    }

    addLinksInputs = () => {
        const { form } = this.props;

        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        form.setFieldsValue({
            keys: nextKeys,
        });
    }

    removeLinkInput = k => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');

        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    }

    renderPreviousLinks = () => {

        let { linkList } = this.state;

        return (
            <List
                itemLayout="horizontal"
                dataSource={linkList}
                renderItem={item => (
                    <List.Item
                        extra={
                            <Popconfirm
                                title="Are you sure, you want to delete this link ?"
                                okText="Yes"
                                onConfirm={() => { this.unlinkNoteLink(item.id) }}
                                cancelText="No"
                            >
                                <a><Icon type="delete" theme="twoTone" /></a>
                            </Popconfirm>
                        }
                    >
                        <List.Item.Meta
                            description={<a target="_blank" href={item.link}>{item.link}</a>}
                        >

                        </List.Item.Meta>

                    </List.Item>
                )}
            >
                {!linkList.length && <></>}
            </List>
        )
    }

    renderLinkInputs = () => {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');

        const formItems = keys.map((k, index) => (

            <Form.Item
                // {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? 'Important Links' : ''}
                required={false}
                key={k}
            >
                {getFieldDecorator(`links[${k}]`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: "Please input the Link.",
                        },
                    ],
                })(<Input placeholder="Link" style={{ width: '90%', marginRight: 10 }} />)}

                <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    onClick={() => this.removeLinkInput(k)}
                />

            </Form.Item>
        ))

        return formItems;
    }

    onCloseDrawer = () => {
        let { onClose, form } = this.props;
        form.resetFields();
        this.setState({
            fileList: [],
            linkList: [],
            noteUpdate: false
        })
        onClose();
    }

    render() {
        const { visible, parentCollection, isNoteUpdate, noteDetails } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { previewVisible, previewImage, fileList, loader } = this.state;
        const defaultValueTag = isNoteUpdate ? map(get(noteDetails, 'tags'), 'tag') : []

        console.log("===>this.state==>", this.state);

        return (
            <div>
                <Drawer
                    title="Create New Note"
                    width={600}
                    destroyOnClose={true}
                    onClose={this.onCloseDrawer}
                    visible={visible}
                >
                    <Spin spinning={loader}>

                        <Form layout="vertical" hideRequiredMark>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Title">
                                        {getFieldDecorator('title', {
                                            initialValue: (isNoteUpdate) ? get(noteDetails, 'title') : "",
                                            rules: [{ required: true, message: 'Please enter a title' }],
                                        })(<Input placeholder="Please enter a title" />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Collection">
                                        {getFieldDecorator('collection_id', {
                                            rules: [{ required: true, message: 'Please select an owner' }],
                                            initialValue: parentCollection.id
                                        })(
                                            <Select
                                                disabled={true}
                                                placeholder="Add To Collection"
                                            >
                                                <Select.Option value={parentCollection.id}>{parentCollection.name}</Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Tags">
                                        {getFieldDecorator('tags', {
                                            initialValue: defaultValueTag
                                        })(
                                            <Select
                                                mode="tags"
                                                size={"default"}
                                                placeholder="Please select"
                                                // onChange={handleChange}
                                                style={{ width: "100%" }}
                                            >
                                                {children}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Note">
                                        {getFieldDecorator('description', {
                                            initialValue: (isNoteUpdate) ? get(noteDetails, 'description') : "",
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'please enter note',
                                                },
                                            ],
                                        })(<Input.TextArea rows={8} placeholder="Please Enter Note" />)}
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row>
                                <Col span={12}>
                                    <Form.Item label="Image">

                                        <div className="clearfix">
                                            <Upload

                                                listType="picture-card"
                                                fileList={fileList}
                                                onPreview={this.handleImagePreview}
                                                onChange={this.onImageUpload}
                                                onRemove={this.removeImage}
                                            >
                                                <div>
                                                    <Icon type="plus" />
                                                    <div className="ant-upload-text">Upload</div>
                                                </div>
                                            </Upload>
                                            <Modal visible={previewVisible} footer={null} onCancel={this.handlePreviewCancel}>
                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>
                                        </div>

                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    {this.renderPreviousLinks()}
                                    {this.renderLinkInputs()}
                                    <Button type="primary" onClick={this.addLinksInputs} style={{ width: "100%", marginTop: 20 }} icon="plus">
                                        Add links
                                 </Button>
                                </Col>

                            </Row>
                            {/**************************** for important links ***********************/}
                            <Row gutter={16}>
                                <Col span={24}>

                                </Col>
                            </Row>


                        </Form>
                        <div
                        // style={{
                        //     position: 'absolute',
                        //     left: 0,
                        //     bottom: 0,
                        //     width: '100%',
                        //     borderTop: '1px solid #e9e9e9',
                        //     padding: '10px 16px',
                        //     background: '#fff',
                        //     textAlign: 'right',
                        // }}
                        >
                            {isNoteUpdate ? (
                                <Button onClick={this.onSubmit} type="primary">Update Notes</Button>
                            ) : (
                                    <>
                                        <Button onClick={this.onCloseDrawer} style={{ marginRight: 8 }}>Cancel</Button>
                                        <Button onClick={this.onSubmit} type="primary">Submit</Button>
                                    </>
                                )}
                        </div>
                    </Spin>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn,
    user: state.auth.user
});

export default connect(mapStateToProps, {
    addNewNote,
    uploadImage,
    updateNote,
    unlinkMedia,
    unlinkNoteLink
})(Form.create()(DrawerForm));
