import * as TYPES from '../constants/actionTypes';
import get from 'lodash/get';
import { axiosInstance } from './../utilities/configureAxios';

export default (state = {
    user : null,
    token : null,
    loggedIn : false,
    families : []
}, action) => {
    
    switch(action.type) {
        
        case TYPES.LOGIN :
            return {...state, token : action.user.token, user : action.user, loggedIn : true}
        
        case TYPES.USER_FAMILIES_FETCHED :
            return {...state, families : action.families}

        case TYPES.LOGOUT :
            return {...state, user: null, token : null, loggedIn : false}

        case 'persist/REHYDRATE':
            const token = get(action.payload, 'auth.token', null);
            if (token) {
                axiosInstance.defaults.headers.common['authorization'] = `Bearer ${token}`;
            }
            return state;
        
        default:
            return state
        
    }
}   