import React, { Component } from 'react';
import { Card, Icon } from 'antd';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import { curveCardinal } from 'd3-shape';

const data = [
    {
        name: 'Jan', uv: 10, pv: 2400, amt: 2400,
    },
    {
        name: 'Feb', uv: 30, pv: 1398, amt: 2210,
    },
    {
        name: 'Mar', uv: 40, pv: 9800, amt: 2290,
    },
    {
        name: 'Apr', uv: 100, pv: 3908, amt: 2000,
    },
    {
        name: 'May', uv: 70, pv: 4800, amt: 2181,
    },
    {
        name: 'Jun', uv: 80, pv: 3800, amt: 2500,
    },
    {
        name: 'Jul', uv: 50, pv: 4300, amt: 2100,
    },
    {
        name: 'Aug', uv: 65, pv: 4300, amt: 2100,
    },
    {
        name: 'Sep', uv: 85, pv: 4300, amt: 2100,
    },
    {
        name: 'Oct', uv: 95, pv: 4300, amt: 2100,
    },
    {
        name: 'Nov', uv: 55, pv: 4300, amt: 2100,
    },
    {
        name: 'Dec', uv: 44, pv: 4300, amt: 2100,
    },
];

const cardinal = curveCardinal.tension(0.2);

export default class QuizScore extends Component {
    render() {
        return (
            <Card>
                <div className="card-header-sec">
                    <div className="title">
                    <Icon type="form" /> Quiz Score
                  </div>
                    <div>
                        <Icon type="setting" /> <Icon type="edit" /> <Icon type="ellipsis" />
                    </div>
                </div>

                <AreaChart
                    width={700}
                    height={290}
                    data={data}
                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area type={cardinal} dataKey="uv" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.3} />
                </AreaChart>
            </Card>
        );
    }
}