import React, { Component } from 'react'
import { Modal,Form,Row,Col,Button,Select,Radio } from 'antd';
import { connect } from "react-redux";


class CreateQuiz extends Component {

    state = {
        loader: false
    }


    loading = (value) => this.setState({ loader: value });

    handleFormSubmit = (event) => {

        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            
            if (!err) {
               this.props.onSubmitCreateQuiz(values)
            }

        })

    }

    render() {
        const { parentCollection, visible, onClose } = this.props;
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title="Create Quiz"
                destroyOnClose={true}
                visible={visible}
                onCancel={onClose}
                footer={null}
                className="add-modal-container">


                <Form onSubmit={this.handleFormSubmit} className="add-collection-form">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label=" Collection">
                                {
                                    getFieldDecorator('collection_id', {
                                        rules: [{ required: true, message: 'You must select a parent collection' }],
                                        initialValue: parentCollection.id
                                    })(
                                        <Select
                                            disabled={true}
                                            placeholder="Parent Collection"
                                        >
                                            <Select.Option value={parentCollection.id}>{parentCollection.name}</Select.Option>
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Include SubCollecion">
                                {getFieldDecorator('include_subcollection', {
                                    rules: [{ required: true, message: 'Please select an option' }],
                                })(
                                    <Radio.Group >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                       
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                        <Form.Item label="Number of question">
                                {getFieldDecorator('limit', {
                                    rules: [{ required: true, message: 'Please select an option' }],
                                    initialValue:5
                                })(
                                    <Select >
                                        <Select.Option value={5}>5</Select.Option>
                                        <Select.Option value={10}>10</Select.Option>
                                        <Select.Option value={15}>15</Select.Option>
                                        <Select.Option value={20}>20</Select.Option>
                                        <Select.Option value={25}>25</Select.Option>
                                        <Select.Option value={30}>30</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button"> create </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>

            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({

});

const CreateQuizWrapper = Form.create({ name: 'createQuiz' })(CreateQuiz);

export default connect(mapStateToProps, {

})(CreateQuizWrapper);
