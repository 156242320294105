import React from 'react';
import { Button, Col, Icon, Row } from 'antd';
import { Link } from 'react-router-dom';
import * as ROUTES from '../constants/routes';

export default props => {

    let { createQuiz, isSharedFolder, selectedCollection, rootCollection, isCollectionEditable } = props;

    let backLink = null

    if (isSharedFolder) {
        if (selectedCollection.level == 0) {
            backLink = ROUTES.COLLECTION.replace(':collectionId', rootCollection.id)
        } else {
            backLink = backLink = ROUTES.COLLECTION.replace(':collectionId', selectedCollection.parent_id)
        }
    } else {
        backLink = (selectedCollection.parent_id) ? ROUTES.COLLECTION.replace(':collectionId', selectedCollection.parent_id) : null
    }

    return (
        <>
            <div className="back-button-container">{backLink && <Link to={backLink} ><Icon type="left" /> Back </Link>}</div>
                 <Row className="action-buttons-container">
                    <Col span={16}>
                        {!isSharedFolder && <Button onClick={props.addCollection} type="ghost" icon="folder-add">Add Collection</Button>}
                        {(!isSharedFolder || isCollectionEditable) && <Button onClick={props.addNote} type="ghost" icon="file-add">Add Note</Button>}
                    </Col>

                    {!isSharedFolder && <Col span={16}>
                        <Button onClick={props.shareCollection} type="ghost" icon="share-alt">Share Collection</Button>
                        <Button onClick={props.showCollectionMembers} type="ghost" icon="team">Collection Members</Button>
                        <Button onClick={props.createQuiz} type="ghost" icon="plus-square">Create Quiz</Button>
                    </Col>}

                </Row>
            
        </>
    )
}