import React from 'react';
import { Alert, Tooltip, Badge, Icon, Button, Table, Divider, Popconfirm } from 'antd';
import get from 'lodash/get'

export default props => {

    const { families, toggleModal } = props;

    const viewMembers = (familyId) => {
        props.viewMembers(familyId);
    }

    const leaveFamily = (familyId) => {

        props.leaveFamily(familyId);
    }

    const renderAdminFamily = (record) => {

        return (
            <>

                <Button onClick={() => { viewMembers(get(record, 'id')) }} type="link">members</Button>
                <Divider type="vertical" />

                <Popconfirm
                    title="Are you sure, you want to leave this family?"
                    okText="Yes"
                    onConfirm={() => { leaveFamily(get(record, 'id')) }}
                    cancelText="No"
                >
                    <a href="#">leave</a>
                </Popconfirm>

                <Divider type="vertical" />
                <Tooltip title="Add Memeber to this family">
                    <a onClick={() => { toggleModal('addFamilyMemberModal', get(record, 'id')) }}><Icon style={{ fontSize: '25px', color: '#1890ff' }} type="user-add" /></a>
                </Tooltip>
                <Divider type="vertical" />
                {(get(record, 'requestCount') > 0) && <Badge count={get(record, 'requestCount')}>
                    <a onClick={() => { toggleModal('memberRequestModal', get(record, 'id')) }}> <Icon style={{ fontSize: '25px' }} type="bell" theme="twoTone" /> </a>
                </Badge>
                }
            </>
        )

    }

    const renderPendingRequest = (record) => {

        return (
            <>
                <Alert type="error" message="Request pending" icon={(<Icon type="pause-circle" theme="twoTone" />)} />
            </>
        )

    }

    const renderMember = (record) => {

        return (
            <>
                <Popconfirm
                    title="Are you sure, you want to leave this family?"
                    okText="Yes"
                    onConfirm={() => { leaveFamily(get(record, 'id')) }}
                    cancelText="No"
                >
                    <a href="#">leave</a>
                </Popconfirm>
            </>
        )
    }


    const columns = [
        { title: 'Family Name', dataIndex: 'name', key: 'name' },
        { title: 'Role', dataIndex: 'role', key: 'role' },
        {
            title: 'Action',
            dataIndex: '',
            key: 'id',
            render: (record) => {

                switch (record.status) {
                    case 0:
                        return renderPendingRequest(record)
                    case 1:
                        return (record.role == 'admin') ? renderAdminFamily(record) : renderMember(record)

                }

            },
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={families}
        ></Table>)
}