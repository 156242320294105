import React from 'react';
import { Card, Row, Col, Tag, Empty } from 'antd';
import notesImage from './../assets/images/notes.png';

export default props => {
    const note = props.note;

    const showNote = () => {
        props.showNote(props.note);
    }

    return (
        <Col span={8} key={note.id}>
            <Card className="head-info" onClick={showNote} className="card-icon bg-light-orange card-shadow">

                <div className="head-info">
                    <img src={notesImage} className="icon-sec mr--15" alt="logo" /> {note.title}
                </div>

                <div className="share-detail-sec">
                    {
                        note.tags && note.tags.map(tag => <Tag className="card-tag">{tag.tag}</Tag>)
                    }

                </div>
                <img src={notesImage} className="icon-backdrop" alt="logo" />
            </Card>
        </Col>
    );
}