import get from 'lodash/get';
import { message } from "antd";
/**
 * Constants
 */
import * as TYPES from './../constants/actionTypes';
import * as APIS from './../constants/apiEndpoints';
import { ADD_QUESTION_SUCCESS,DELETE_QUESTION_SUCCESS,UPDATE_QUESTION_SUCCESS } from '../constants/messages'

export const addNewQuestion = payload => (dispatch, getState, { api }) => {

    return new api.post(APIS.ADD_NEW_QUESTION, payload)
        .then(res => {
            message.success(ADD_QUESTION_SUCCESS);
            return res;

        }).catch((error) => {
            throw error;
        });
}

export const updateQuestion = (payload,questionId) => (dispatch, getState, { api }) => {
    
    return new api.patch(APIS.UPDATE_QUESTION.replace(':questionId',questionId), payload)
        .then(res => {
            message.success(UPDATE_QUESTION_SUCCESS);
            return res;

        }).catch((error) => {
            throw error;
        });
}

export const deleteQuestion = questionId => (dispatch, getState, { api }) => {

    let url = APIS.DELETE_QUESTION.replace(':questionId', questionId);

    return new api.delete(url).then(res => {
        message.success(DELETE_QUESTION_SUCCESS);
        return res;

    }).catch((error) => {
        throw error;
    });
}