import React, { Component } from 'react'
import { Modal } from 'antd';
import { connect } from "react-redux";
import AddCollectionForm from './AddCollectionForm';
import { addNewCollection } from './../actions/collectionActions';

class AddCollectionModal extends Component {

    handleSubmit = collection => {
        const { addNewCollection, onClose } = this.props;
        
        this.loading(true);
        addNewCollection(collection).then(res => {
            this.loading(false);
            onClose();
        }).catch(error => {
            this.loading(false);
        })
    }

    loading = (value) => this.setState({loading : value});

    render() {
        const { parentCollection, visible, onClose } = this.props;
        return (
                    <Modal title="Add Collection" destroyOnClose={true} visible={visible} onCancel={onClose} footer={null} className="add-modal-container">
                        <AddCollectionForm parentCollection={parentCollection} onSubmit={this.handleSubmit} />
                    </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    loggedIn : state.auth.loggedIn,
    user : state.auth.user
  });
  
export default connect(mapStateToProps, {
    addNewCollection
})(AddCollectionModal);
