import axios from "axios";
import { notification } from "antd";
import get from "lodash/get";
import * as TYPES from './../constants/actionTypes';

const configureAxios = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 25000,
  });

export const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use(config => {

  return config;
});

axiosInstance.interceptors.response.use(response => {

  return response;
}, err => {
  console.log("err", err.response.data);
  if (!err.response) {
    notification['error']({
      message: "Something Went Wrong",
      description: "Please Check Your Internet Connection"
    });

  }
  else {
    import('./../redux/store').then((persistStore) => {
      const { store } = persistStore.default;
      let state = store.getState();

      if (err.response.status === 401 && state.auth.loggedIn) {
        store.dispatch({ type: TYPES.LOGOUT });
      }

      if (err.response.status === 422) {
        notification['error']({
          message: get(err, 'response.data.message', "Something Went Wrong")
        });
      } else {
        notification['error']({
          message: get(err, 'response.data.message', "Something Went Wrong")
        });
      }
    })


  }
  return Promise.reject(get(err, 'response.data'));
});