import React, { Component } from 'react'
import { Menu, Dropdown, Avatar, Icon } from 'antd';
import ProfileImage from './../assets/images/profile/team11.jpg';



export default props => {
    const { name, logout, goToProfile } = props;
    const menu = (
        <Menu className="profile-dropdown-sec">
            <Menu.Item onClick={goToProfile}>
                    <Icon type="form" /> Profile
            </Menu.Item>
            <Menu.Item onClick={logout}>
                    <Icon type="logout" />  Logout
            </Menu.Item>
        </Menu>
    );
    return (
        <Dropdown overlay={menu} placement="bottomRight">
            <div className="user-img"><Avatar src={ProfileImage} width="60" className="icon-sec" alt="logo" /> {name}</div>
        </Dropdown>
    )
}
