/** BASE_URL */


/** Auth */
export const LOGIN = "/login";
export const SIGNUP = "/signup";

/** User */
export const GET_USER_FAMILIES = "/users/families";
export const SEARCH_USER = "users?name="

/** Summary */
export const SUMMARY = "/summary";

/** Collections */
export const GET_COLLECTION_BY_ID = "/collections/:collectionId";
export const ADD_NEW_COLLECTION = "/collections";
export const GET_SHARED_COLLECTION = "/shares";
export const SHARE_COLLECTION = "/shares";
export const GET_COLLECTION_USER = "/collections/:collectionId/users";

/** Notes */
export const ADD_NEW_NOTE = "/notes";
export const GET_NOTE_BY_ID = "/notes/:noteId";
export const UPDATE_NOTE = "/notes/:noteId";
export const UNLINK_NOTE_MEDIA = "/media/:mediaUuid";
export const UNLINK_NOTE_LINK = "notes/:noteId/link/:linkId";

/** Media */
export const UPLOAD_IMAGE = "/media";


/** Families */
export const GET_FAMILY_USERS = "/families/:familyId/users"
export const GET_PENDING_REQUEST_FAMILY_USERS = "/families/:familyId/users?type=pending_request"
export const ACTION_ON_PENDING_REQUEST = "/users/families/:familyId/family-user/:familyUserId"
export const LEAVE_FAMILY = "/users/families/:familyId?action=leave"
export const JOIN_FAMILY = "/users/families/:familyId?action=join"
export const CREATE_FAMILY = "/users/families"
export const SEARCH_FAMILIES = "families?q="
export const ADD_FAMILY_TO_MEMBER = "users/:userId/families/:familyId";


/** Questions */
export const ADD_NEW_QUESTION = "/questions";
export const DELETE_QUESTION = "/questions/:questionId"
export const UPDATE_QUESTION = "/questions/:questionId"


/** Quiz */
export const CREATE_NEW_QUIZ = "/quizzes";
export const GET_NEW_QUIZ = "/quizzes/:quizId";
export const SUBMIT_QUIZ_RESPONSE = "quizzes/:quizId/submit";
export const GET_ALL_QUIZ = "/quizzes"
export const REATTEMPT_QUIZ = "/quizzes/:quizId/re-attempt"
export const QUIZ_RESULT = "/quizzes/:quizId/result"