import React, { Component } from 'react'
import { Result, Button, Typography, Icon } from 'antd';
import { Link } from 'react-router-dom';
import * as ROUTES from './../constants/routes';
import { connect } from "react-redux";
import get from 'lodash/get';
import { getQuiz } from '../actions/quizAction';
import requireAuth from '../hocs/requireAuth';
const { Paragraph, Text } = Typography;

class QuizResultScreen extends Component {

    state = {
        loader: false,
        quiz: null
    };

    componentDidMount() {
        let { match } = this.props;
        let { quizId } = get(match, 'params');
        this.getQuizDetails(quizId);
    }

    loading = (value) => this.setState({ loader: value });

    getQuizDetails = (quizId) => {

        let { getQuiz } = this.props;
        this.loading(true);

        getQuiz(quizId)
            .then((result) => {
                this.setState({ quiz: result });
                this.loading(false);
            }).catch((error) => {
                this.loading(false);
            });
    }


    render() {
        let { quiz } = this.state;

        return (
            <Result
                status="success"
                title={`Quiz submitted `}
                subTitle={`you have successfully submitted ${get(quiz, 'name')} quiz`}
                extra={[
                    <Link to={ROUTES.COLLECTIONS_HOME}>
                        <Button type="primary" key="console">
                            Go To Collection
                    </Button>
                    </Link>,
                    <Link to={ROUTES.DASHBOARD}>
                        <Button key="buy">Dashboard</Button>
                    </Link>,
                ]}
            >

                <div className="desc" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Paragraph>
                        <Text
                            strong
                            style={{
                                fontSize: 24,
                                color: 'green'
                            }}
                        >
                            {` Your Score : ${get(quiz, 'correct_answer')} / ${get(quiz, 'total_questions')}`}
                        </Text>
                    </Paragraph>
                </div>

            </Result>
        )
    }
}


const mapStateToProps = (state) => ({});

export default requireAuth(connect(mapStateToProps, { getQuiz })(QuizResultScreen))


