import React from 'react';
import { Drawer, Icon, Upload, Modal, Select, Radio, Spin, Form, Row, Col, Input, Checkbox, Button, InputNumber } from 'antd';
import { connect } from 'react-redux';
import takeRight from 'lodash/takeRight';
import { addNewQuestion, updateQuestion } from '../actions/questionAction';
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import {uploadImage,unlinkMedia} from '../actions/mediaAction'
import { fetchCollectionById } from '../actions/collectionActions'
import remove from 'lodash/remove';
const { confirm } = Modal;


class AddQuestionDrawer extends React.Component {

    state = {
        loader: false,
        showSubjectiveInput: false,
        showObjectiveType: false,
        showObjectiveOption: false,
        questionType: null,
        objectiveType: null,
        answers: [],
        answerOption: [],
        previewVisible: false,
        previewImage: null,
        type: null, ///this is fiinal question type,
        fileList: [],
        images: [],
        event:null,
        optionCount:2
    };

    onCloseDrawer = () => {
        let { onClose } = this.props;

        onClose();
        this.setState({
            loader: false,
            showSubjectiveInput: false,
            showObjectiveType: false,
            showObjectiveOption: false,
            questionType: null,
            objectiveType: null,
            answers: [],
            answerOption: [],
            type: null,
            fileList: [],
            images: [],
            images:[],
            previewVisible: false,
            previewImage: null,
        });
    }

    componentWillReceiveProps = (nextProps) => {

        if (nextProps.isQuestionUpdate && nextProps.questionForUpdate && get(nextProps, 'questionForUpdate.id') != get(this.props, 'questionForUpdate.id')) {

            if (get(nextProps, 'questionForUpdate.type') == 0) {
                this.setState({ showSubjectiveInput: true, questionType: 1, type: 0 })
            } else {
                let stateObj = { showObjectiveType: true, showObjectiveOption: true, questionType: 2 };
                stateObj['type'] = get(nextProps, 'questionForUpdate.type');
                let options = get(nextProps, 'questionForUpdate.options');
                let answers = JSON.parse(get(nextProps, 'questionForUpdate.answers'));
                let answerOption = answers.map(answer => indexOf(options, answer))
                
                stateObj['answerOption'] = answerOption;
                stateObj['optionCount'] = options.length
                this.setState(stateObj);
            }
        }

    }

    onClickAnswerOption = (answerArr) => {
        let { type } = this.state;

        if (type == 1) {
            this.setState({ answerOption: takeRight(answerArr) });
        } else {
            this.setState({ answerOption: answerArr });
        }
    }

    loading = (loader) => this.setState({ loader });

    renderMulipleChoice = () => {
        const { getFieldDecorator } = this.props.form;
        let { answerOption,optionCount } = this.state;
        let { isQuestionUpdate, questionForUpdate } = this.props;
        let options = (isQuestionUpdate) ? get(questionForUpdate, 'options') : [];

        let optionNames = ["A","B","C","D","E","F","G","H","I","J","K","L"];
        optionNames = optionNames.splice(0,optionCount)
        console.log("optionNames",optionNames);
        return (
            <>
                <Row gutter={16}>
            
                    {optionNames.map((optionName, i) => {
                        
                            return (
                                <Col span={12}>
                                    <Form.Item label={optionNames[i]}>
                                        {getFieldDecorator(`options[${i}]`, {
                                            initialValue: (isQuestionUpdate) ? options[i] : "",
                                            rules: [{ required: true, message: 'Please enter option value' }]
                                        })(<Input placeholder="Please enter the option value" />)}
                                    </Form.Item>
                                </Col>
                            )
                        
                    })}

                </Row>

                <Row gutter={16}>
                    <Form.Item label="Option Answer">
                        <Checkbox.Group onChange={this.onClickAnswerOption} style={{ width: '100%' }} value={answerOption} >
                            {optionNames.map((optionName,i) => {
                                
                                return (
                                    <Col span = {6}>
                                        <Checkbox value={i}> {optionName} </Checkbox>
                                    </Col>
                                )
                            })}
                            
                        </Checkbox.Group>
                    </Form.Item>
                </Row>
            </>
        )
    }

    onSelectQuestionType = (e) => {

        if (e.target.value == 1) {
            this.setState({ questionType: e.target.value, showSubjectiveInput: true, showObjectiveType: false, showObjectiveOption: false, type: 0, answers: [], answerOption: [] });
        } else {
            this.setState({ questionType: e.target.value, showObjectiveType: true, showSubjectiveInput: false, type: null, answers: [], answerOption: [] });
        }
    }

    onSelectObjectiveType = (e) => {

        if (e.target.value == 1) {
            this.setState({
                showObjectiveOption: true,
                type: 1,
                answerOption: [],
                answers: []
            });
        } else {
            this.setState({
                showObjectiveOption: true,
                type: 2,
                answerOption: [],
                answers: []
            });
        }
    }

    onSubmit = (event) => {

        event.preventDefault();
        const { form, isQuestionUpdate, addNewQuestion, updateQuestion, fetchCollectionById, collection_id, questionForUpdate } = this.props;
        
        form.validateFields((err, values) => {

            if (!err) {
                this.loading(true);

                let payloads = this.generatePayload(values);
                this.loading(false);
                if (!isQuestionUpdate) {
                    addNewQuestion(payloads)
                        .then(() => {
                            this.loading(false);
                            this.onCloseDrawer();
                            fetchCollectionById(collection_id);
                        }).catch(() => {
                            this.loading(false);
                        })
                } else {
                    updateQuestion(payloads, get(questionForUpdate, 'id')).then((result) => {
                        this.loading(false);
                        this.onCloseDrawer();
                        fetchCollectionById(collection_id);
                    }).catch((error) => {
                        this.loading(false);
                    })
                }
            }
        })
    }

    generatePayload = (formValues) => {

        let { collection_id, note_id, title, answer, options } = formValues;
        let { type, answerOption, images } = this.state;

        let returnObj = {};
        returnObj['collection_id'] = collection_id;
        returnObj['note_id'] = note_id;
        returnObj['title'] = title;
        returnObj['type'] = type;
        returnObj['answers'] = [];
        returnObj['images'] = images; 

        switch (type) {
            case 0:
                returnObj['answers'].push(answer);
                break;

            case 1:
                returnObj['answers'].push(options[answerOption[0]]);
                returnObj['options'] = options;
                break;

            case 2:
                answerOption.map((value) => {
                    returnObj['answers'].push(options[value])
                });
                returnObj['options'] = options;
                break;
        }

        return returnObj;
    }

    handlePreviewCancel = () => {
        this.setState({
            previewVisible: false,
            previewImage: null
        })
    }

    handleImagePreview = (file) => {
        // alert("hiii");
        this.setState({
            previewImage: file.url,
            previewVisible: true
        });

    }

    onImageUpload = async (fileObj) => {

        const { uploadImage } = this.props;
        let {event} = this.state;

        if (event == 'delete') {
            confirm({
                title: 'Do you Want to delete this Image?',
                onOk: () => {
                    this.deleteImage(fileObj);
                },
                onCancel() {
                    console.log('Cancel');
                },
            });

        } else {
            uploadImage(fileObj, 2)
                .then(async (result) => {
                    var { fileList, images } = this.state;

                    fileList.push({
                        uid: get(result, 'id'),
                        name: 'image.png',
                        status: 'done',
                        url: get(result, 'url'),
                    })
                    images.push(get(result, 'id'))
                    this.setState({ fileList, images });
                })
                .catch((error) => {
                });
        }
    }

    deleteImage = async (fileObj) => {
        let fileUuid = get(fileObj, 'file.uid');
        var { fileList, images } = this.state;

        if (images.indexOf(fileUuid) == -1) {
            await this.unlinkMedia(fileUuid)
        } else {
            remove(images, (uuid) => {
                return fileUuid == uuid
            })
        }

        remove(fileList, (Obj) => {
            return fileUuid == Obj.uid
        })


        this.setState({ fileList, images, event: null })

    }

    unlinkMedia = (mediaUuid) => {
        this.loading(true);
        let { unlinkMedia } = this.props;

        unlinkMedia(mediaUuid)
            .then((result) => {
                this.loading(false);
                return result;
            }).catch((error) => {
                this.loading(false);
            })
    }

    removeImage = async (index, index2) => {
        console.log("index", index, index2);
        await this.setState({ event: 'delete' });
    }


    render() {

        let { previewVisible, previewImage, visible, notes, parentCollection, isQuestionUpdate, questionForUpdate } = this.props;

        let { loader, fileList,showSubjectiveInput,
            showObjectiveType, showObjectiveOption,optionCount,
            questionType, objectiveType, type, answerOption } = this.state;

        const { getFieldDecorator } = this.props.form;
            console.log("this.state",this.state);
        return (
            <div>
                <Drawer
                    title="Add new Question"
                    width={600}
                    destroyOnClose={true}
                    onClose={this.onCloseDrawer}
                    visible={visible}
                >
                    <Spin spinning={loader}>

                    <Modal visible={previewVisible} footer={null} onCancel={this.handlePreviewCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>

                        <Form layout="vertical" hideRequiredMark>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Collection">
                                        {getFieldDecorator('collection_id', {
                                            rules: [{ required: true, message: 'Please select an owner' }],
                                            initialValue: parentCollection.id
                                        })(
                                            <Select
                                                disabled={true}
                                                placeholder="Add To Collection"
                                            >
                                                <Select.Option value={parentCollection.id}>{parentCollection.name}</Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Note">
                                        {getFieldDecorator('note_id', {
                                            rules: [{ required: true, message: 'Please select note' }],
                                            initialValue: (isQuestionUpdate) ? get(questionForUpdate, 'note_id') : null,
                                        })(
                                            <Select
                                                placeholder="Add To Notes"
                                            >
                                                {notes.map((note) => {
                                                    return (<Select.Option value={note.id}> {note.title} </Select.Option>)
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Question Title">
                                        {getFieldDecorator('title', {
                                            rules: [{ required: true, message: 'Question title is required' }],
                                            initialValue: (isQuestionUpdate) ? get(questionForUpdate, 'title') : "",
                                        })(<Input placeholder="Please enter a title" />)}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Images">
                                        {/* {getFieldDecorator('images', {
                                            valuePropName: 'fileList',
                                        })( */}
                                              <div className="clearfix">
                                                <Upload

                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    onPreview={this.handleImagePreview}
                                                    onChange={this.onImageUpload}
                                                    onRemove={this.removeImage}
                                                >
                                                    <div>
                                                        <Icon type="plus" />
                                                        <div className="ant-upload-text">Upload</div>
                                                    </div>
                                                </Upload>
                                               
                                            </div>
                                        {/* )} */}
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Question type">
                                        {getFieldDecorator('question_type', {
                                            initialValue: (isQuestionUpdate) ? questionType : null,
                                            rules: [{ required: true, message: 'Please select question type' }],
                                        })(
                                            <Radio.Group value={questionType} onChange={this.onSelectQuestionType}>
                                                <Radio value={1}>Subjective</Radio>
                                                <Radio value={2}>Objective</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            {showObjectiveType && <Row gutter={16}>

                                <Col span={16}>
                                    <Form.Item label="Objective Question type">
                                        {getFieldDecorator('objective_type', {
                                            initialValue: (isQuestionUpdate) ? type : null,
                                            rules: [{ required: true, message: 'Please select Objective question type' }],
                                        })(
                                            <Radio.Group onChange={this.onSelectObjectiveType} value={type}>
                                                <Radio value={1}>Multiple choice single answer</Radio>
                                                <Radio value={2}>Multiple choice multiple answer</Radio>
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>}

                            {showSubjectiveInput && <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Answer">
                                        {getFieldDecorator('answer', {})(<Input.TextArea placeholder="Please enter the answer" />)}
                                    </Form.Item>
                                </Col>
                            </Row>}

                            { showObjectiveOption && <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Number of option">
                                        {getFieldDecorator('optionCount', {
                                            initialValue:optionCount
                                        })(  <InputNumber size="large" min={2} max={100000} onChange = { (count) => (this.setState({optionCount : count })) }/> ) }
                                    </Form.Item>
                                </Col>
                            </Row>}

                            {showObjectiveOption && this.renderMulipleChoice()}

            

                        </Form>

                        <div>
                            <Button onClick={this.onCloseDrawer} style={{ marginRight: 8 }}>Cancel</Button>
                            <Button onClick={this.onSubmit} type="primary">{isQuestionUpdate ? 'Update' : 'Submit'} </Button>
                        </div>
  
                    </Spin>
                </Drawer>
            </div>
        );

    }
}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, { unlinkMedia,addNewQuestion,uploadImage, updateQuestion, fetchCollectionById })(Form.create()(AddQuestionDrawer));