import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Layout } from 'antd';

import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import Header from './components/Header';

import LoginScreen from "./screens/LoginScreen";
import SignupScreen from "./screens/SignupScreen";
import HomeScreen from './screens/HomeScreen';
import QuizScreen from './screens/quizScreen'
import CollectionScreen from './screens/CollectionScreen';
import ProfileScreen from './screens/ProfileScreen';
import QuizMainScreen from './screens/QuizMainScreen'
import QuizResultScreen from './screens/QuizResultScreen';
import QuizSolutionScreen from './screens/QuizSolutionScreen';

import * as ROUTES from './constants/routes';

export default class AppRouter extends Component {
  render() {
    return (
      <Router>
        <div className="mainContainer main-section">
          <Layout>
            <Sidebar />
            <div className="main-content-wrapper">
              <Header />
              <Layout className="content-wrapper">
                <Route path={ROUTES.DASHBOARD} exact component={HomeScreen} />
                <Route path={ROUTES.LOGIN} component={LoginScreen} />
                <Route path={ROUTES.PROFILE} component={ProfileScreen} />
                <Route path="/signup/" component={SignupScreen} />
                <Route path={ROUTES.COLLECTIONS_HOME} exact={true} component={CollectionScreen} />
                <Route path={ROUTES.COLLECTION} component={CollectionScreen} />
                <Route exact path={ROUTES.QUIZ_MAIN_PAGE} component={QuizMainScreen} />
                <Route path={ROUTES.QUIZ} component={QuizScreen} />
                <Route path={ROUTES.QUIZ_RESULT} component={QuizResultScreen} />
                <Route path={ROUTES.QUIZ_SOLUTION} component={QuizSolutionScreen} />
              </Layout>
              <Footer />
            </div>
          </Layout>
        </div>
      </Router>
    )
  }
}