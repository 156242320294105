import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { Form, Modal, Spin, Icon, Button, Select } from 'antd';
import { searchFamily, joinFamily } from '../../actions/familyActions';
import { getUserFamilies } from '../../actions/userActions';
import { connect } from "react-redux";

const { Option } = Select;


let timeout;
let currentValue;


class JoinFamilyModal extends PureComponent {

    state = {
        families: [],
        familyId: null,
        fetching: false,
        data: [],
        value: undefined,
    }

    componentDidMount = () => {
        // this.seachFamily("test");
    }

    handleSubmit = e => {
        const { joinFamily, toggleModal, getUserFamilies } = this.props;
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {

                joinFamily(get(values, 'familyId')).then(() => {
                    this.setState({ families: [], familyId: null ,data:[],value:null})
                    toggleModal('joinFamilyModal')
                    getUserFamilies();
                })

            }
        });
    };


    onSelectFamily = (familyId) => {
        this.setState({ familyId });
    }

    handleSearch = value => {
        if (value) {
            this.seachFamily(value, data => this.setState({ data }));
        } else {
            this.setState({ data: [] });
        }
    };

    handleChange = value => {
        this.setState({ value });
    };

    seachFamily = (value, callback) => {

        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        currentValue = value;
        const { searchFamily } = this.props;

        function queryFamilyData() {

            const data = [];

            searchFamily(value).then((result) => {
                result.map((val) => {
                    data.push({
                        value: val.id,
                        text: val.name
                    })
                })

                callback(data);

            }).catch((error) => {

            })
        }

        timeout = setTimeout(queryFamilyData, 100);
    }


    render() {

        const { getFieldDecorator } = this.props.form;
        const { toggleModal, modalVisible } = this.props;
        const { families, familyId, fetching } = this.state;
        // const childern = families.map(family => <Select.Option key = {`${family.id}`} value = {`${family.id}`}>{family.name}</Select.Option>)       
        const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>);
        console.log("this.state==>", this.state);
        return (

            <Modal
                visible={modalVisible}
                footer={null}
                onCancel={() => { toggleModal('joinFamilyModal') }}
                title="Join a new family">


                <div onSubmit={this.handleSubmit} className="families-card-container">
                    <Form >
                        <Form.Item label="Family name">
                            {getFieldDecorator('familyId', {
                                rules: [{ required: true, message: 'Please select a family !' }],
                            })(
                                <Select
                                    showSearch
                                    value={this.state.value}
                                    placeholder={this.props.placeholder}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    style={{ width: '100%' }}
                                    onSearch={this.handleSearch}
                                    onChange={this.handleChange}
                                    notFoundContent={null}
                                >
                                    {options}
                                </Select>
                            )}
                        </Form.Item>
                        <Button type="primary" block htmlType="submit" className="btn-primary ant-btn ant-btn-primary">
                            Join Family
                        </Button>
                    </Form>
                </div>
            </Modal>
        )
    }
}
const JoinFamilyWrapper = Form.create({ name: 'join_family' })(JoinFamilyModal);

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, { getUserFamilies, searchFamily, joinFamily })(JoinFamilyWrapper);
