import React, { Component } from 'react'
import { Button, Alert, Icon, Row, Col, message, Tag, Spin, Card, Anchor, Form, List, Input, Modal, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import * as ROUTES from './../constants/routes';
import { connect } from "react-redux";
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { getQuizResult } from '../actions/quizAction';
import requireAuth from '../hocs/requireAuth';


class QuizSolutionScreen extends Component {

    state = {
        questions: [],
        responses: [],
        loader: true,
        previewVisible: false,
        previewImage: null,
    }

    componentDidMount() {
        let { match } = this.props;
        let { quizId } = get(match, 'params');
        this.getQuizDetails(quizId);
    }

    loading = (value) => this.setState({ loader: value });

    getQuizDetails = (quizId) => {

        let { getQuizResult } = this.props;
        this.loading(true);

        getQuizResult(quizId)
            .then((result) => {
                if (get(result, 'status') != 1) {
                    message.error('This Quiz is not completed yet..!!');
                    this.props.history.push(ROUTES.QUIZ_MAIN_PAGE)
                } else {
                    this.setState({ quizName: get(result, 'name'), questions: get(result, 'questions'), quizId, responses: get(result, 'responses') })
                }
                this.loading(false);

            }).catch((error) => {

                message.error('Oops, no such Quiz exists,you can create the new one');
                this.props.history.push(ROUTES.QUIZ_MAIN_PAGE)
                this.loading(false);
            })
    }


    handlePreview = (url) => {
        this.setState({
            previewVisible: true,
            previewImage: url,
        })
    }

    handlePreviewCancel = () => {

        this.setState({
            previewVisible: false,
            previewImage: null,
        })

    }


    renderImage(question) {

        if ('questionMedia' in question) {

            return (
                <Row>
                    <List
                        itemLayout="horizontal"
                        dataSource={question['questionMedia']}
                        renderItem={item => (
                            <Col span={4}>
                                <Card
                                    bodyStyle={{ padding: '0px' }}
                                    hoverable={true}
                                    bordered={false}
                                    style={{ width: 150 }}
                                    cover={
                                        <img
                                            onClick={() => { this.handlePreview(`${process.env.REACT_APP_API_URL}/media/${item.uuid}`) }}
                                            style={{ border: '1px solid grey' }}
                                            alt="example"
                                            src={`${process.env.REACT_APP_API_URL}/media/${item.uuid}`}
                                        />
                                    }
                                />
                            </Col>
                        )}
                    />
                </Row>
            )

        }

    }


    renderQuestion = (question, index) => {

        let { responses } = this.state;
        let questionType = get(question, 'type');
        let questionResponse = find(responses, ['question_id', get(question, 'id')]);
        let responseAnswers = get(questionResponse, 'answer');
        let options = get(question, 'options');
        responseAnswers = (responseAnswers) ? JSON.parse(responseAnswers) : [""];
        let answers = (get(question, 'answers')) ? JSON.parse(get(question, 'answers')) : [];

        switch (questionType) {

            case (0):
                console.log("get(question,'was_correct')", get(question, 'was_correct'));
                return (
                    <div className="select-section">
                        <div className="bg-question-section "> <span className="number-style">{index + 1}</span> <h4 className="title title-xs">{get(question, 'title')}</h4></div>
                        <Form.Item>
                            <div style={{ margin: 10 }}>
                                <Tag>Subjective </Tag>
                                {get(questionResponse, 'was_correct') ? <Alert style={{ width: '50%' }} message="Correct" type="success" showIcon /> : <Alert style={{ width: '50%' }} message="Wrong" type="error" showIcon />}
                            </div>

                            {this.renderImage(question)}

                            <Input label="Your answer" size="large" className="checkbox-group-style" style={{ width: '90%' }} value={responseAnswers[0]} ></Input>
                        </Form.Item>
                        <h4>Correct Answer : {answers[0]}</h4>
                    </div>
                )

            case (1):

                return (
                    <div className="select-section">
                        <div className="bg-question-section"> <span className="number-style">{index + 1}</span> <h4 className="title title-xs">{get(question, 'title')}</h4></div>
                        <Form.Item >
                            <div style={{ margin: 10 }}>
                                <Tag>Multiple Choice with single answer</Tag>
                                {get(questionResponse, 'was_correct') ? <Alert style={{ width: '50%' }} message="Correct" type="success" showIcon /> : <Alert style={{ width: '50%' }} message="Wrong" type="error" showIcon />}
                            </div>
                            {this.renderImage(question)}
                            <Checkbox.Group value={responseAnswers} className="checkbox-group-style" >
                                {options.map((option) => <Checkbox value={option}>{option}</Checkbox>)}
                            </Checkbox.Group>
                        </Form.Item>
                        <h4>Correct Answer : {map(answers, (answer) => (<Tag color="blue">{answer}</Tag>))}</h4>
                    </div>
                )

            case (2):

                return (
                    <div className="select-section">
                        <div className="bg-question-section"> <span className="number-style">{index + 1}</span> <h4 className="title title-xs">{get(question, 'title')}</h4></div>
                        <Form.Item>
                            <div style={{ margin: 10 }}>
                                <Tag>Multiple Choice with multiple answer</Tag>
                                {get(questionResponse, 'was_correct') ? <Alert style={{ width: '50%' }} message="Correct" type="success" showIcon /> : <Alert style={{ width: '50%' }} message="Wrong" type="error" showIcon />}
                            </div>
                            {this.renderImage(question)}
                            <Checkbox.Group value={responseAnswers} className="checkbox-group-style" onChange={(e) => { this.handleAnswerInputChange(e, question) }}>
                                {options.map((option) => <Checkbox value={option}>{option}</Checkbox>)}
                            </Checkbox.Group>
                        </Form.Item>
                        <h4>Correct Answer : {map(answers, (answer) => (<Tag color="blue">{answer}</Tag>))}</h4>
                    </div>
                )
        }


    }

    render() {

        let { loader, responses, questions, quizName, previewVisible, previewImage } = this.state;

        let correct = filter(responses, ['was_correct', 1]);
        correct = correct.length;

        return (

            <Spin spinning={loader}>
                <div className="quiz-container-style">
                    <div className="question-title">
                        <h4 className="title title-md"> {quizName} </h4>
                        <Alert style={{ width: '30%' }} message={`Score : ${correct} out of ${questions.length}`} type="success" showIcon />
                    </div>

                    {questions.map((question, index) => this.renderQuestion(question, index))}

                </div>
                <Modal visible={previewVisible} width={1000} footer={null} onCancel={this.handlePreviewCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </Spin>

        )
    }
}

const mapStateToProps = (state) => ({});

export default requireAuth(connect(mapStateToProps, { getQuizResult })(QuizSolutionScreen))

