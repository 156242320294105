export const DASHBOARD = "/";
export const PROFILE = "/me";
export const LOGIN = "/login";
export const SIGNUP = "/signup";

export const COLLECTIONS_HOME = "/collections";
export const COLLECTION = "/collections/:collectionId";

export const QUIZ_MAIN_PAGE = "/quizzes"
export const QUIZ = "/quiz/:quizId";
export const QUIZ_RESULT = "/quiz-result/:quizId";
export const QUIZ_SOLUTION = "/quiz-solution/:quizId";