import React, { Component } from 'react';
import SignupForm from './../components/SignupForm';
import { Row, Col, Spin } from 'antd';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as ROUTES from './../constants/routes';

class SignUpScreen extends Component {

  state = {
    loading: false
  }

  startLoading = () => {
    this.setState({ loading: true });
  }
  stopLoading = () => {
    this.setState({ loading: false });
  }

  render() {

    const { loggedIn } = this.props;
    const { loading } = this.state;

    if (loggedIn) return (<Redirect to={ROUTES.DASHBOARD} />)

    return (
      <div className="login-section bg-illustration">

        <div className="container">
          <Row type="flex" justify="end" align="middle" className="row-height">
            <Col xs={24} sm={16} lg={8}>
              <div className="card card-login primary-center-shadow">
                <div className="mb--20">
                  <h4 className="title title-md mb--0">Sign UP</h4>
                  <small className="text-light">Please fill in this form to create an account!</small>
                </div>
                <Spin spinning={loading}>
                  <div className="fx">
                    <SignupForm startLoading={this.startLoading} stopLoading={this.stopLoading} ></SignupForm>
                  </div>
                </Spin>
              </div>
            </Col>
          </Row>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn
});

export default connect(mapStateToProps, {})(SignUpScreen);
