import React, { Component } from 'react';
import { Modal, Spin, Breadcrumb, Icon, Col, Row, Tag, Button, Typography, Divider, List } from 'antd';
import get from 'lodash/get'
import map from 'lodash/map'
const { Title, Paragraph, Text } = Typography;

export default class QuestionDetailCard extends Component {

    state = {
        loader: false,
        previewVisible: false,
        previewImage: null,
        
    }

    renderOptions = () => {

        let { question } = this.props;
        console.log("====>question",question);
        if (question) {
            if (get(question, 'type') == 0) {
                return;
            } else {

                const options = get(question, 'options');

                return (
                    <>
                        <Row gutter={16}>
                            <Col span={20}>
                                <Title level={4}>Options</Title>
                                <Paragraph>
                                    <ol>
                                        {options.map(option => <li>{option}</li>)}
                                    </ol>
                                </Paragraph>
                            </Col>
                        </Row>

                    </>
                )
            }
        }

    }

    renderAnswer = () => {

        let { question } = this.props;

        if (question) {
            let answers = JSON.parse(get(question, 'answers'))
            if (get(question, 'type') == 0) {
                return (
                    <Row gutter={16}>
                        <Col span={20}>
                            <Title level={4}>Answer</Title>
                            <Paragraph>
                                {answers[0]}
                            </Paragraph>
                        </Col>
                    </Row>
                )
            }
            else if (get(question, 'type') == 1) {
                return (
                    <Row gutter={16}>
                        <Col span={20}>
                            <Title level={4}>Answer</Title>
                            <Paragraph>
                                <Tag color="blue">{answers[0]}</Tag>
                            </Paragraph>
                        </Col>
                    </Row>
                )
            } else {
                return (
                    <Row gutter={16}>
                        <Col span={20}>
                            <Title level={4}>Answer</Title>
                            <Paragraph>
                                <ul>
                                    {answers.map(answer => <Tag color="blue">{answer}</Tag>)}
                                </ul>
                            </Paragraph>
                        </Col>
                    </Row>
                )
            }
        }

    }

    handlePreviewCancel = () => {
        this.setState({
            previewVisible: false,
            previewImage: null
        })
    }

    handleImagePreview = (media) => {
        this.setState({
            previewVisible: true,
            previewImage: media
        })
    }

    renderImage = () => {

        let { question } = this.props;
        let { previewVisible, previewImage } = this.state

        if (question && 'questionMedia' in question && question['questionMedia'].length) {
            let media = map(question['questionMedia'], (image) => {
                return `${process.env.REACT_APP_API_URL}/media/${image.uuid}`
        });

            return (
                <Row gutter={16}>
                    <List
                        header={<Title underline={true} level={4}>Notes Media</Title>}
                        dataSource={media}
                        renderItem={item => {
                            return (
                                <Col span={4} style={{ width : '50%'}}>
                                    <a><img style={{ width : '100%',border: "1px solid black", margin:1}} onClick={() => { this.handleImagePreview(item) }} alt="example" src={item} /> </a>
                                </Col>
                            )
                        }}
                    >
                    </List>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handlePreviewCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </Row>
            )
        }
    }

    render() {

        let { visible, editQuestion, note, toggleQuestiondetailModal, question, isSharedFolder } = this.props;
        let { loader } = this.state;

        let questionType = (get(question, 'type') == 0) ? "Subjective" : ((get(question, 'type') == 1) ? "Objective (Multiple choice with single answer)" : "Objective (Multiple choice with multiple answer)")

        return (

            <Modal
                visible={visible}
                onCancel={toggleQuestiondetailModal}
                closable={true}
                maskClosable={true}
                cancelText="Close"
                // width={'auto'}
                okText={(!isSharedFolder) ? "Edit question" : false}
                onOk={() => { (!isSharedFolder) ? editQuestion(question) : toggleQuestiondetailModal() }}
                style={{ padding: 10 }}
                title={(
                    <Breadcrumb>
                        <Breadcrumb.Item>{"Question"}</Breadcrumb.Item>
                    </Breadcrumb>
                )}
            >
                <Spin spinning={loader}>
                    <div className="families-card-container">

                        <Row gutter={16}>
                            <Col span={24}>
                                <Tag style={{ flexWrap: 'wrap' }} color="orange">{note}</Tag>
                            </Col>
                        </Row>

                        <br />

                        <Row gutter={16}>
                            <Col span={20}>
                                <Title level={4}>Question title</Title>
                                <Paragraph>
                                    {get(question, "title", "")}
                                </Paragraph>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Title level={4}>Question Type</Title>
                                <Paragraph>
                                    {questionType}
                                </Paragraph>
                            </Col>
                        </Row>

                        {this.renderOptions()}

                        {this.renderAnswer()}

                        {this.renderImage()}


                    </div>
                </Spin>
            </Modal>
        )
    }

}