import React, { Component } from 'react'
import { Form, Spin, Button, Select, Row, Col,Radio } from 'antd';
import { getAdminFamilies } from '../actions/userActions';
import { getFamilyUsers } from '../actions/familyActions';
import {shareCollection} from '../actions/collectionActions';
import { connect } from 'react-redux'
import get from 'lodash/get';

class ShareCollectionForm extends Component {

    state = {
        families: [],
        familyUsers: [],
        loader:false
    }

    componentDidMount() {
        this.getFamilies();
    }

    startLoader = () => {
        this.setState({loader:true})
    }

    stoptLoader = () => {
        this.setState({loader:false})
    }

    handleFormSubmit = event => {
        event.preventDefault();
        let {shareCollection,onClose} = this.props;
        this.startLoader();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.startLoader();

                shareCollection(values)
                .then(()=>{
                    this.stoptLoader();
                    onClose();
                })
                .catch(()=>{
                    this.stoptLoader();
                })
                
            }
        });

    }

    getFamilies = () => {

        let { getAdminFamilies } = this.props;

        getAdminFamilies()
            .then((families) => {
                this.setState({ families });
            }).catch((error) => {

            })
    }

    onSelectFamily = (familyId) => {

        let { getFamilyUsers } = this.props;

        getFamilyUsers(familyId)
            .then((familyUsers) => {
                this.setState({ familyUsers });
            }).catch((error) => {
                console.log("=====>>>", error);
            })

    }


    /**
     * Render
     */
    render() {
        const { parentCollection, user } = this.props;
        const { getFieldDecorator } = this.props.form;

        const { families, familyUsers,loader} = this.state;

        console.log("this.state", this.state);


        return (
            <Spin spinning={loader}>
            <div className="add-form-container">
              
                <Form onSubmit={this.handleFormSubmit} className="add-collection-form">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Shared Collection">
                                {getFieldDecorator('collection_id', {
                                    rules: [{ required: true, message: 'You must select a parent collection' }],
                                    initialValue: parentCollection.id
                                })(
                                    <Select
                                        showArrow={false}
                                        disabled={true}
                                        placeholder="Parent Collection"
                                    >
                                        <Select.Option value={parentCollection.id}>{parentCollection.name}</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Family">
                                {getFieldDecorator('family_id', {
                                    rules: [{ required: true, message: 'Please select a family' }],
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a Family"
                                        optionFilterProp="children"
                                        onChange={this.onSelectFamily}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {families.map((family) => {

                                            return (<Select.Option value={get(family, 'id')}>{get(family, 'name')}</Select.Option>)

                                        })}

                                    </Select>,
                                )}
                            </Form.Item>

                            <Form.Item label="Family Member">
                                {getFieldDecorator('user_id', {
                                    rules: [{ required: true, message: 'Please add a name for this collection' }],
                                })(
                                    <Select
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >

                                        {familyUsers.map((familyUser) => {

                                            return ((user.id != familyUser.id) && <Select.Option value={get(familyUser, 'id')}>{get(familyUser, 'name')}</Select.Option>)

                                        })}

                                    </Select>,
                                )}
                            </Form.Item>


                            <Form.Item label="Share subfolders">
                                {getFieldDecorator('subfolders_shared', {
                                    rules: [{ required: true, message: 'Please select, you want to share subcollections' }],
                                })(
                                    <Radio.Group >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>

                            <Form.Item label="Share subfolders">
                                {getFieldDecorator('permission', {
                                    rules: [{ required: true, message: 'Please select, you want to share subcollections' }],
                                })(
                                    <Radio.Group >
                                        <Radio value={1}>Can View</Radio>
                                        <Radio value={2}>Can Edit</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>


                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button"> Save </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                
            </div>
            </Spin>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
});

let shareCollectionWrapper = Form.create({ name: 'share_CollectionForm' })(ShareCollectionForm);

export default connect(mapStateToProps, {
    getAdminFamilies,
    getFamilyUsers,
    shareCollection
})(shareCollectionWrapper)