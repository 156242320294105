import React, { Component } from 'react';
import { Card, Icon, Tag } from 'antd';

import { List, Avatar } from 'antd';

const data = [
    {
        title: 'Vanila Dohe',
    },
    {
        title: 'Navina Sohre',
    },
    {
        title: 'Salina Khan',
    },
    {
        title: 'John Dohe',
    },
];

export default class TodayTask extends Component {
    render() {
        return (
            <Card className="card-list-task">
                <div className="card-header-sec">
                    <div className="title">
                        <Icon type="calendar" />
                        Recent Comments
              </div>

                </div>
                <List
                    className="list-relative-sec"
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar src="https://experience.sap.com/fiori-design-web/wp-content/uploads/sites/5/2017/02/Avatar-Sizes-Custom-1.png" />}
                                title={<a href="https://ant.design">{item.title}</a>}
                                description="Ant Design, a design language for background"
                            />
                            <div className="flex-column-style">
                                <div className="list-date-sec">March 12, 2019</div>
                                <Tag color="#87d068">Success</Tag>
                            </div>
                        </List.Item>
                    )}
                />
            </Card>
        )
    }
}
