import React, { Component } from 'react'
import {Spin, Table ,Popconfirm, Tag} from 'antd';
import {getAllUserQuiz} from '../actions/quizAction'
import get from 'lodash/get';
import { connect } from "react-redux";
import requireAuth from '../hocs/requireAuth';
import moment from 'moment';
import {reAttemptQuiz} from '../actions/quizAction';
import * as ROUTES from '../constants/routes'

function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
}

class QuizMainScreen extends Component {

    state = { loader : false,quizzes:[] };

    componentDidMount() {
        
        this.getAllQuizzes();
    }

    loading = (loader) => this.setState({loader});

    getAllQuizzes = () => {
        let {getAllUserQuiz} = this.props;
        getAllUserQuiz()
        .then((result) => {
            console.log("======>",result);
            this.setState({quizzes:result})
        }).catch((error)=>{
            console.log("error",error);
        })
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    OnSubmitReAttemptQuiz = (quizId) => {
        this.loading(true)
        let {reAttemptQuiz} = this.props;

        reAttemptQuiz(quizId)
        .then((result) => {
            
            this.loading(false);
            this.props.history.push(ROUTES.QUIZ.replace(':quizId',quizId))
        }).catch((error)=>{
            this.loading(false);
            console.log("error",error);
        })
    }

    render() {

        const columns = [
            {
                title: 'Collections',
                dataIndex: 'QuizCollection.name',
                key: 'collection_name',
            },

            {
                title: 'SubFolder Shared',
                dataIndex: 'include_subcollection',
                key: 'address',
                render : is_subfolder_included => {

                        if(is_subfolder_included)
                        return "YES"
                        else
                        return "NO"
                }
            },
            {
              title: 'Quiz Name',
              dataIndex: 'name',
              key: 'name',
              render: text => <a>{text}</a>,
            },
            {
              title: 'Date',
              dataIndex: 'createdAt',
              key: 'date',
              render : date => {
                  console.log("==>date",moment(date).format("MMMM Do YYYY"));
                  return (`${ moment(date).format("MMMM Do, YYYY") }`)
              }
            },
            {
              title: 'Total question',
              dataIndex: 'total_questions',
              key: 'questions',
            },
            {
                title: 'Score',
                dataIndex: 'correct',
                key: 'correct',
            },

            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render : status => {
                    if(status == 1 )
                        return (<Tag color="#108ee9">Completed</Tag>)
                    else
                     return (<Tag color="gold">Pending</Tag>)
                }
            },

            {
                title: 'Action',
                // dataIndex: 'status',
                key: 'status',
                render: (value) => {
                    console.log("===>value",value);
                    return (
                    <>
                        {get(value,'status') == 0 && 
                            <Popconfirm
                                title="Are you sure, you want to re-attempt this quiz ?"
                                okText="Yes"
                                onConfirm={() => { this.OnSubmitReAttemptQuiz(get(value,'id')) }}
                                cancelText="No"
                            ><a>Re-attempt</a>
                            </Popconfirm>
                        }
                        {get(value,'status') == 1 && <a href={`${ROUTES.QUIZ_SOLUTION.replace(':quizId',get(value,'id'))}`}>View Solution</a> }
                    </>)
                }
            },
          
          ];
          

      let {quizzes,loader} = this.state;


        return (
            <div className="quiz-container-style" style={{backgroundColor:"white"}}>
                <Spin spinning={loader}>
               <Table columns={columns} dataSource={quizzes} />
               </Spin>
            
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

export default requireAuth(connect(mapStateToProps, {
    getAllUserQuiz,
    reAttemptQuiz
  })(QuizMainScreen));
