import React, { Component } from 'react';
import { List, Card, Icon, Tag } from 'antd';
import { getFormattedDate } from '../utilities/dates';
import NoteDetailCard from './NoteDetailCard';

export default class LatestNotes extends Component {

    state={
        selectedNote : null
    }

    closeNote = () => {
        this.setState({selectedNote : null});
    }

    render() {
        const { notes } = this.props;
        const { selectedNote } = this.state;
        return (
            <Card>
                <div className="card-header-sec">
                    <div className="title">
                        <Icon type="form" /> Latest Notes
                     </div>
                    <div>
                        <Icon type="info-circle" /> <Icon type="delete" /> <Icon type="printer" />
                    </div>
                </div>

                <List
                    className="list-content-style"
                    itemLayout="horizontal"
                    dataSource={notes}
                    pagination={{
                        onChange: page => {
                        },
                        pageSize: 4,
                    }}
                    renderItem={note => (
                        <List.Item 
                        className="clickable"
                        onClick={()=> {this.setState({selectedNote : note})}}
                        >
                            <List.Item.Meta
                                
                                title={<div className="clickable" >{note.title}</div>}
                                description={note.description && note.description.substr(0, 40)}
                            />
                            <div className="list-sec">
                                <h4 className="list-title">
                                    Collection
                                </h4>
                                <p className="list-text-dark">Dashboard</p>
                            </div>
                            <div className="list-sec">
                                <h4 className="list-title">
                                    Date Added
                                </h4>
                                <p className="list-text-dark">{getFormattedDate(note.createdAt)}</p>
                            </div>
                        </List.Item>
                    )}
                />
            {selectedNote && <NoteDetailCard visible={selectedNote} note={selectedNote} onCancel={this.closeNote}/>}
            </Card>
        );
    }
}

