import get from 'lodash/get';
/**
 * Constants
 */
import * as TYPES from './../constants/actionTypes';
import * as APIS from './../constants/apiEndpoints';

export const addNewNote = note => (dispatch, getState, { api }) => {
    return new api.post(APIS.ADD_NEW_NOTE, note).then(res => {
        dispatch({
            type : TYPES.NOTE_CREATED,
            note : get(res, 'data.data')
        });
    });
}

export const updateNote = (noteId,noteDetails) => (dispatch, getState, { api }) => {
    return new api.patch(APIS.UPDATE_NOTE.replace(':noteId',noteId), noteDetails).then(res => {
        return res;
    }).catch((error)=>{
        throw error;
    });
}



export const unlinkNoteLink = (noteId,linkId) => (dispatch, getState, { api }) => {
    let url = APIS.UNLINK_NOTE_LINK.replace(':noteId',noteId);
    url = url.replace(':linkId',linkId)
    return new api.delete(url).then(res => {
        return res;
    }).catch((error)=>{
        throw error;
    });
}

export const getNote = noteId => (dispatch, getState, { api }) => {
    return new api.get(APIS.GET_NOTE_BY_ID.replace(':noteId',noteId)).then(res => {
        
        return get(res,'data.data');
    }).catch((error)=>{
        throw error;
    });
}