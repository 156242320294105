import React, { Component } from 'react'
import { Button, Row, Col, message, Tag, Spin, Card, Anchor, Form, List, Input, Modal, Checkbox } from 'antd';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import get from 'lodash/get';
import { getQuiz, submitQuiz } from '../actions/quizAction';
import { connect } from "react-redux";
import find from 'lodash/find';
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import takeRight from 'lodash/takeRight';
import remove from 'lodash/remove';
import head from 'lodash/head'
import moment from 'moment'
import * as ROUTES from '../constants/routes';
import requireAuth from '../hocs/requireAuth';

const { confirm } = Modal;


function onChange(checkedValues) {
    console.log('checked = ', checkedValues);
}

class QuizScreen extends Component {

    state = {
        visible: false,
        quizId: null,
        questions: [],
        answers: [],
        quizName: "",
        loader: false,
        startTiming: null,
        quizTiming: null
    };

    componentDidMount() {
        let { match } = this.props;
        let { quizId } = get(match, 'params');
        this.getQuizDetails(quizId);
        setInterval(this.runTime, 1000);
    }

    runTime = () => {

        let { startTiming } = this.state;
        if (startTiming) {
            let duration = moment.duration(moment().diff(startTiming))
            this.setState({ quizTiming: `${duration.get('hours')} : ${duration.get('minutes')} : ${duration.get('seconds')}` })
        }
    }

    loading = (value) => this.setState({ loader: value });

    getQuizDetails = (quizId) => {

        let { getQuiz } = this.props;
        this.loading(true);
        getQuiz(quizId)
            .then((result) => {
                if (get(result, 'status') != 0) {
                    message.error('This Quiz is already submitted');
                    this.props.history.push(ROUTES.COLLECTIONS_HOME)
                } else {
                    this.setState({ startTiming: get(result, 'updatedAt'), quizName: get(result, 'name'), questions: get(result, 'questions'), quizId })
                }
                this.loading(false);

            }).catch((error) => {
                message.error('Oops, no such Quiz exists,you can create the new one');
                this.props.history.push(ROUTES.COLLECTIONS_HOME)
                this.loading(false);
            })
    }

    handleAnswerInputChange = (value, question) => {

        // let value = e.target.value;
        let questionId = get(question, 'id');
        let { answers } = this.state;
        let answerIndex = findIndex(answers, ['id', questionId]);
        let questionType = get(question, 'type');

        if (answerIndex >= 0) {

            switch (questionType) {

                case 0:
                    if (value)
                        answers[answerIndex]['answers'] = [value];
                    else
                        remove(answers, (answer) => { return answer.id == questionId });
                    break;

                case 1:
                    if (value.length)
                        answers[answerIndex]['answers'] = takeRight(value)
                    else
                        remove(answers, (answer) => { return answer.id == questionId });
                    break;

                case 2:
                    if (value.length)
                        answers[answerIndex]['answers'] = value
                    else
                        remove(answers, (answer) => { return answer.id == questionId });
                    break;
            }

        } else {
            answers.push({
                id: get(question, 'id'),
                answers: (questionType == 0) ? [value] : value
            })

        }

        this.setState({ answers })
    }




    onSubmitQuiz = () => {

        let { questions, answers, quizId } = this.state;
        let { submitQuiz } = this.props;

        confirm({
            title: 'Are you sure, you want to submit the quiz',
            content: (<>
                <p><Tag color="blue"> Total Question </Tag> {questions.length} </p>
                <p><Tag color="green"> Question attempted </Tag>{answers.length} </p>
                <p><Tag color="red"> Question unattempted </Tag> {questions.length - answers.length} </p>
            </>),
            okText: "Submit",
            onOk: () => {
                this.loading(true);
                submitQuiz(quizId, { responses: answers })
                    .then((result) => {
                        this.loading(false);
                        this.props.history.push(ROUTES.QUIZ_RESULT.replace(':quizId', quizId))
                    }).catch((error) => {

                        this.loading(false);

                    })
            },
            onCancel() {

            },
        });

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handlePreview = (url) => {
        this.setState({
            previewVisible: true,
            previewImage: url,
        })
    }

    handlePreviewCancel = () => {

        this.setState({
            previewVisible: false,
            previewImage: null, 
        })

    }


    renderImage(question) {

        if ('questionMedia' in question) {
            console.log("==>question.questionMedia",question.questionMedia.length)
          
                return (
                    <Row>
                        <List
                            itemLayout="horizontal"
                            dataSource={question['questionMedia']}
                            renderItem={item => (
                                <Col span={4}>
                                    <Card
                                        bodyStyle = {{padding:'0px'}}
                                        hoverable={true}
                                        bordered={false}
                                        style={{ width: 150 }}
                                        cover={
                                            <img
                                                onClick={() => { this.handlePreview(`${process.env.REACT_APP_API_URL}/media/${item.uuid}`) }}
                                                style={{border:'1px solid grey'}}
                                                alt="example"
                                                src={`${process.env.REACT_APP_API_URL}/media/${item.uuid}`}
                                            />
                                        }
                                    />
                                </Col>
                            )}
                        />
                    </Row>
                )
          
        }

    }

    renderQuestion = (question, index) => {

        let questionType = get(question, 'type');
        let options = get(question, 'options');
        let { answers } = this.state;
        let questionId = get(question, 'id');

        let defaultAnswer = find(answers, ['id', questionId]);

        defaultAnswer = (defaultAnswer) ? defaultAnswer.answers : [];

        const { getFieldDecorator } = this.props.form;

        switch (questionType) {

            case (0):

                return (
                    <div className="select-section">
                        <div className="bg-question-section"> <span className="number-style">{index + 1}</span> <h4 className="title title-xs">{get(question, 'title')}</h4></div>

                        <Form.Item >
                            <Tag>Subjective </Tag>
                            {this.renderImage(question)}
                            <Input size="large" className="checkbox-group-style" style={{ width: '90%' }} value={head(defaultAnswer)} onChange={(e) => { this.handleAnswerInputChange(e.target.value, question) }} placeholder="Please enter your answer"></Input>

                        </Form.Item>
                    </div>
                )

            case (1):

                return (
                    <div className="select-section">
                        <div className="bg-question-section"> <span className="number-style">{index + 1}</span> <h4 className="title title-xs">{get(question, 'title')}</h4></div>
                        <Form.Item>
                            <Tag>Multiple Choice with single answer</Tag>
                            {this.renderImage(question)}
                            <Checkbox.Group value={defaultAnswer} className="checkbox-group-style" onChange={(e) => { this.handleAnswerInputChange(e, question) }}>
                                {options.map((option) => <Checkbox value={option}>{option}</Checkbox>)}
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                )

            case (2):

                return (
                    <div className="select-section">
                        <div className="bg-question-section"> <span className="number-style">{index + 1}</span> <h4 className="title title-xs">{get(question, 'title')}</h4></div>
                        <Form.Item>
                            <Tag>Multiple Choice with multiple answer</Tag>
                            {this.renderImage(question)}
                            <Checkbox.Group value={defaultAnswer} className="checkbox-group-style" onChange={(e) => { this.handleAnswerInputChange(e, question) }}>
                                {options.map((option) => <Checkbox value={option}>{option}</Checkbox>)}
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                )
        }


    }

    render() {

        let { questions, quizName, loader, quizTiming,previewVisible,previewImage } = this.state;

        return (
            <Spin spinning={loader}>
                <div className="quiz-container-style">
                    <Form>

                        <div className="question-title">
                            <h4 className="title title-md"> {quizName} </h4>
                            <Anchor>
                                <div className="time-sec">Time Taken <div className="time">{quizTiming}</div></div>
                            </Anchor>
                        </div>

                        { questions.map((question, index) => this.renderQuestion(question, index)) }

                        <div className="footer-section">
                            {/* <button type="submit" className="ant-btn btn-primary ant-btn-primary"><span>Prew</span></button> */}
                            <div className="right-section">
                                <button onClick={this.onSubmitQuiz} type="submit" className="ant-btn btn-blue ant-btn-primary"><span>Submit Quiz</span></button>
                                {/* <button type="submit" className="ant-btn btn-blue ant-btn-primary"><span>View Marked Questions</span></button> */}
                                {/* <button type="submit" className="ant-btn btn-primary ant-btn-primary"><span>Next</span></button> */}
                            </div>
                        </div>


                        {/* <Button type="primary" onClick={this.showModal}> Open FlipCard Section</Button> */}
                    </Form>

                    <Modal visible={previewVisible} width={1000} footer={null} onCancel={this.handlePreviewCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>

                    <Modal
                        visible={this.state.visible}
                        footer={null}
                        centered={true}
                        closable={null}
                        width={'auto'}
                        className="flipmodalCard"
                    >
                        <Flippy
                            flipOnHover={false} // default false
                            flipOnClick={true} // default false
                            flipDirection="horizontal" // horizontal or vertical
                            ref={(r) => this.flippy = r} // to use toggle method like this.flippy.toggle()
                            style={{ width: '200px', height: '200px' }}>

                            <FrontSide className="frontstyle">
                                <h4 className="title title-md">Any title here</h4>
                                <p className="info"> The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.</p>
                                <button type="submit" className="ant-btn btn-primary ant-btn-primary"><span>View More</span></button>
                            </FrontSide>
                            <BackSide className="backstyle">
                                <p className="info">
                                    The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
                        </p>
                            </BackSide>
                        </Flippy>
                    </Modal>
                </div>
            </Spin>
        )
    }
}



const QuizScreenWrapper = Form.create({ name: 'quiz' })(QuizScreen);

const mapStateToProps = (state) => ({});

export default requireAuth(connect(mapStateToProps, { getQuiz, submitQuiz })(QuizScreenWrapper))




// <div className="select-section">
// <div className="bg-question-section"> <span className="number-style">{index + 1}</span> <h4 className="title title-xs">{get(question, 'title')}</h4></div>
// <Checkbox.Group className="checkbox-group-style" onChange={onChange}>
//     <Checkbox value="A">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Checkbox>
//     <Checkbox value="B">In ornare felis at porttitor consequat.</Checkbox>
//     <Checkbox value="C" className="wrong-answer">Etiam pharetra tellus id ultricies cursus.</Checkbox>
//     <Checkbox value="D" className="correct-answer">Aenean eget nisl non lorem congue convallis interdum at ipsum.</Checkbox>
// </Checkbox.Group>
// </div>