import React, { Component } from 'react';
import { Table, Card, Icon, Progress  } from 'antd';
import { getFormattedDate } from '../utilities/dates';


const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Percentage',
        dataIndex: 'total_questions',
        align : 'center',
        render : (total_questions, quiz) =>  quiz.correct*100/total_questions
    },
    {
        title: 'Statement',
        dataIndex: 'correct',
        render : (correct, quiz) => <Progress percent={(correct/quiz.total_questions)*100} showInfo={false} size="small" />
    },
    {
        title: 'Date',
        dataIndex: 'createdAt',
        align : 'center',
        render : (createdAt, quiz) => getFormattedDate(createdAt)
    },
];

export default class LatestNotes extends Component {

    render() {
        const { quizzes } = this.props;
        return (
            <Card className="table-primary">
                <div className="card-header-sec">
                    <div className="title">
                        <Icon type="form" /> Recent Quizes
                     </div>
                    <div>
                        <Icon type="info-circle" /> <Icon type="delete" /> <Icon type="printer" />
                    </div>
                </div>

                <Table columns={columns} rowKey='id' dataSource={quizzes} size="small" />

            </Card>
        );
    }
}

