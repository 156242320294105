import get from 'lodash/get';
/**
 * Constants
 */

import * as APIS from './../constants/apiEndpoints';
import axios from 'axios';

export const uploadImage = (file,type) => async (dispatch, getState, { api }) => {

    var bodyFormData = new FormData();
    
    bodyFormData.append('file', file.file.originFileObj); 
    bodyFormData.append('type', type); 
    let url = `${process.env.REACT_APP_API_URL}${APIS.UPLOAD_IMAGE}`

    return  axios({
        method: 'post',
        url: url,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(function (response) {
            //handle success
            
            return get(response,'data.data')
        })
        .catch(function (response) {
            console.log(response);
        });

}

export const unlinkMedia = (mediaUuid) => (dispatch, getState, { api }) => {
    
    let url = APIS.UNLINK_NOTE_MEDIA.replace(':mediaUuid',mediaUuid)

    return new api.delete(url).then(res => {
        return res;
    }).catch((error)=>{
        throw error;
    });
}


