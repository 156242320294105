import get from 'lodash/get';
/**
 * Constants
 */
import * as TYPES from './../constants/actionTypes';
import * as APIS from './../constants/apiEndpoints';


export const fetchCollectionById = (collectionId = null,isSharedCollection=false) => (dispatch, getState, { api }) => {
    if (!collectionId) {
        collectionId = get(getState(), 'auth.user.rootCollection.id');
    }
    return new api.get(APIS.GET_COLLECTION_BY_ID.replace(':collectionId', collectionId)).then(res => {

        dispatch({
            type: TYPES.FETCH_COLLECTION,
            collection: get(res, 'data.data'),
        });
    });
}

export const fetchSharedCollection = (collectionId = null) => (dispatch, getState, { api }) => {

    return new api.get(APIS.GET_SHARED_COLLECTION).then(res => {
        dispatch({
            type: TYPES.FETCH_SHARED_COLLECTION,
            sharedCollection: get(res, 'data.data.data'),
        });
    }).catch((error) => {
        throw error;
    });

}

export const addNewCollection = collection => (dispatch, getState, { api }) => {
    return new api.post(APIS.ADD_NEW_COLLECTION, collection).then(res => {
        dispatch({
            type: TYPES.COLLECTION_CREATED,
            collection: get(res, 'data.data')
        });
    });
}

export const shareCollection = dataobj => (dispatch, getState, { api }) => {

    return new api.post(APIS.SHARE_COLLECTION, dataobj)
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("error", error);
            throw error;
        })
}

export const stopShareCollection = dataobj => (dispatch, getState, { api }) => {

    return new api.delete(APIS.SHARE_COLLECTION, {
        data: dataobj
    })
        .then((result) => {
            return result;
        })
        .catch((error) => {
            console.log("error", error);
            throw error;
        })
}

export const fetchCollectionUser = (collectionId) => (dispatch, getState, { api }) => {
    console.log("collectionId", collectionId);
    return new api.get(APIS.GET_COLLECTION_USER.replace(':collectionId', collectionId)).then((result) => {
        return get(result, 'data.data.data');
    }).catch((error) => {
        throw error;
    })
}   