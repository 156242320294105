import React, { Component } from 'react';
import LoginForm from './../components/LoginForm';
import { Row, Col, spin } from 'antd';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { login } from './../actions/authActions'
import * as ROUTES from './../constants/routes';

class LoginScreen extends Component {

  state = {
    loading: false
  }

  onLoginSubmit = credentials => {
    const { login, history } = this.props;

    this.setState({ loading: true });

    login(credentials).then(res => {
      this.setState({ loading: false });
      history.push(ROUTES.DASHBOARD);
    }).catch(res => {
      this.setState({ loading: false });
    });
  }

  render() {

    const { loggedIn } = this.props;
    const { loading } = this.state;
    if (loggedIn) return (<Redirect to={ROUTES.DASHBOARD} />)

    return (
      <div className="login-section bg-illustration">
        <div className="container">
          <Row type="flex" justify="end" align="middle" className="row-height">
            <Col xs={24} sm={16} lg={8}>
              <div className="card card-login primary-center-shadow">
                <h4 className="title title-md mb--20">Login to your account</h4>
                <spin spinner={loading}>
                  <div className="fx">
                    <LoginForm onSubmit={this.onLoginSubmit}></LoginForm>
                  </div>
                </spin>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn
});

export default connect(mapStateToProps, {
  login
})(LoginScreen);