import React, { Component } from 'react'
import { Layout } from 'antd';
const { Footer } = Layout;

export default class Navbar extends Component {
  render() {
    return (
      <Footer style={{ textAlign: 'center' }} className="footer-primary">
        <span className="text-uppercase">Study Planner</span> © 2019
      </Footer>
    )
  }
}
