import React, { PureComponent } from 'react'
import { Icon, List, Button, Modal } from 'antd';
import get from 'lodash/get'

export default class MembersList extends PureComponent {
    render() {
        const { users, onCloseMemberModal, family } = this.props;
        return (
            <Modal
                title={get(family, 'name')}
                visible={true}
                onCancel={this.props.onCloseMemberModal}
                footer={[
                    <Button key="back" onClick={this.props.onCloseMemberModal}>
                        Close
                </Button>,
                ]}
            >
                <div className="families-card-container">
                    {/* <Card title="Family Members" className="families-card"> */}
                        <List
                            dataSource={users}
                            renderItem={user => (
                                <List.Item
                                    key={user.id}
                                >
                                    <List.Item.Meta
                                        title={`${user.first_name} ${user.last_name} @${user.username}`}
                                        description={(
                                            <>
                                                <p> <Icon rotate={90} theme="twoTone" type="phone" /> {user.phone} </p>
                                                <p> <Icon theme="twoTone" type="mail" /> {user.email} </p>
                                                
                                            </>
                                        )}
                                    />
                                    <div>{user.FamilyUser.role}</div>
                                </List.Item>
                            )}
                        ></List>
                    {/* </Card> */}
                </div>
            </Modal>
        )
    }
}
