import React, { Component } from 'react';
import { Modal, Spin, Breadcrumb, Icon, Col, Row, Tag, Button, Typography, Divider, List } from 'antd';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import { getNote } from '../actions/noteActions'
import { connect } from "react-redux";
import get from 'lodash/get'
const { Title, Paragraph, Text } = Typography;

class NoteDetailCard extends Component {

    state = {
        id: null,
        title: null,
        description: null,
        collection_id: null,
        tags: [],
        links: [],
        media: [],
        previewImage: null,
        previewVisible: false,
        loader: false,
        
    }

    componentDidMount() {
        let { note } = this.props;
        this.setState({
            id: get(note, 'id'),
            title: get(note, 'title'),
            description: get(note, 'description'),
            collection_id: get(note, 'collection_id')
        });
    }

    componentWillMount() {

        let { getNote, note } = this.props;
        this.startLoading();
        getNote(get(note, 'id'))
            .then((result) => {
                this.stopLoading();
                this.setState(result);
            }).catch((error) => {
                this.stopLoading();
            })

    }

    startLoading = () => {
        this.setState({ loader: true });
    }

    stopLoading = () => {
        this.setState({ loader: false });
    }

    handleImagePreview = (file) => {

        // alert("hiii")
        this.setState({
            previewImage: file.url,
            previewVisible: true
        });

    }

    handlePreviewCancel = () => {
        this.setState({
            previewVisible: false,
            previewImage: null
        })
    }

    render() {

        const { visible, onCancel, editNotes, note, selectedCollection, isCollectionEditable } = this.props;
        let { loader, title, previewVisible, previewImage, description, media, links, tags } = this.state;

        return (
            <Modal
                visible={visible}
                onCancel={onCancel}
                closable={true}
                maskClosable={true}
                title={(
                    <Breadcrumb>
                        <Breadcrumb.Item>{selectedCollection.name}</Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                )}
                width={'auto'}
                style={{ padding: 10 }}
            >
                <Spin spinning={loader}>
                    <div className="families-card-container">
                        <Row gutter={16} style={{ marginBottom: 10 }} >
                            <Col span={18}>

                                <List
                                    dataSource={tags}
                                    renderItem={item => <Tag color="cyan">{item.tag}</Tag>}
                                >

                                </List>
                            </Col>

                        </Row>
                        <Row gutter={16} >
                            <Col span={18}>
                                <Title underline={true} level={4}>Description</Title>
                                <Paragraph>
                                    {description}
                                </Paragraph>
                            </Col>
                            <Col span={1}>
                                <Divider orientation="right" type="vertical" style={{ height: '15vh' }} />
                            </Col>
                            <Col span={5} style={{ flexDirection: 'flex-end' }}>
                                {isCollectionEditable && <Button type="primary" icon="edit" onClick={() => { editNotes(this.state) }}>
                                    Edit Notes
                                </Button>}
                                <List
                                    itemLayout="horizontal"
                                    header={<Title underline={true} level={4}>Important Links</Title>}
                                    dataSource={links}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                description={<a target="_blank" href={item.link}>{item.link}</a>}
                                            />
                                        </List.Item>
                                    )}
                                >

                                </List>
                            </Col>
                        </Row>

                        <Row gutter={16}>

                            {/* <Col span={24}> */}
                            <List
                                header={<Title underline={true} level={4}>Notes Media</Title>}
                                dataSource={media}
                                renderItem={item => {
                                    return (
                                        <Col span={4}>
                                            <a><img onClick={() => { this.handleImagePreview(item) }} alt="example" style={{ width: '50%' }} src={item.url} /> </a>
                                        </Col>
                                    )
                                }}
                            >
                            </List>
                            <Modal visible={previewVisible} footer={null} onCancel={this.handlePreviewCancel}>
                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>

                        </Row>

                    </div>
                </Spin>
            </Modal>
        )
    }
}


const mapStateToProps = (state) => ({
    selectedCollection: state.collection.selectedCollection
});

export default connect(mapStateToProps, {
    getNote
})(NoteDetailCard);
