import * as TYPES from '../constants/actionTypes';
import { axiosInstance } from './../utilities/configureAxios';

export default (state = {
    selectedCollection : null,
    sharedCollection:[]
}, action) => {
    
    switch(action.type) {
        
        case TYPES.FETCH_COLLECTION :
            return {...state, selectedCollection : action.collection};
        
        case TYPES.FETCH_SHARED_COLLECTION :
            return {...state, sharedCollection : action.sharedCollection}

        case TYPES.COLLECTION_CREATED :
            if(action.collection.parent_id === state.selectedCollection.id) {
                
                let { subCollections } = state.selectedCollection;
                subCollections = [...subCollections, action.collection];

                const selectedCollection = {...state.selectedCollection, subCollections};
                return {...state, selectedCollection };
            }
        
        case TYPES.NOTE_CREATED :
                if(action.note.collection_id === state.selectedCollection.id) {
                    debugger;
                    let { notes } = state.selectedCollection;
                    notes = [...notes, action.note];
    
                    const selectedCollection = {...state.selectedCollection, notes};
                    return {...state, selectedCollection };
                }

        case TYPES.LOGOUT :
            return {...state, selectedCollection : null}
        
        default:
            return state
        
    }
}   