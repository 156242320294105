import React, { Component, Fragment } from 'react'
import { Layout, Menu, Icon } from 'antd';
import logo from './../assets/images/man-reading-primary.svg';
import { withRouter, Link } from 'react-router-dom';
import { matchPath } from "react-router";

import * as ROUTES from './../constants/routes';
const { Sider } = Layout;

class Sidebar extends Component {

  state = {
    menuItems: [
      { name: 'Dashboard', icon : 'home', route: ROUTES.DASHBOARD },
      { name: 'Profile', icon : 'user', route: ROUTES.PROFILE },
      { name: 'Collections', icon : 'database', route: ROUTES.COLLECTIONS_HOME },
      { name: 'Quizes', icon : 'book', route: ROUTES.QUIZ_MAIN_PAGE },
      // { name: 'Empty', icon : 'line-chart', route: ROUTES.COLLECTIONS_HOME },
      // { name: 'Empty', icon : 'usergroup-add', route: ROUTES.COLLECTIONS_HOME },
    ],
    selectedKeys : []
  }

  static getDerivedStateFromProps = (nextProps, state) => {
    const { location } = nextProps;
    const selectedMenuItems =  state.menuItems.filter(item => {
      return matchPath(location.pathname, { path: item.route, exact: true });
    })
    return {selectedKeys : selectedMenuItems.map(item => item.name)}
  }

  showSidebar = () => {
    const { location } = this.props;
    if (
      matchPath(
        location.pathname,
        { path: ROUTES.LOGIN, exact: true }
      ) || matchPath(
        location.pathname,
        { path: ROUTES.SIGNUP, exact: true })
    ) {
      return false;
    };
    return true;
  }

  render() {
    if (!this.showSidebar()) return (<Fragment></Fragment>);
    const { menuItems, selectedKeys } = this.state;
    return (
      <Layout className="sidebar-section">
        <Sider className="sidebar-panel secondary-shadow">
          <div className="logo text-center">
            <img src={logo} width="70" alt="logo" />
          </div>
          <Menu theme="light" mode="inline" selectedKeys={selectedKeys}>
            {menuItems.map(menuItem => <Menu.Item key={menuItem.name}>
              <Link to={menuItem.route}><Icon type={menuItem.icon} />
                <span>{menuItem.name}</span>
              </Link>
            </Menu.Item>)
            }
          </Menu>
        </Sider>
      </Layout>
    );
  }
}

export default withRouter(Sidebar);

// ReactDOM.render(<Sidebar />, mountNode);