import get from 'lodash/get';
/**
 * Constants
 */
import * as TYPES from './../constants/actionTypes';
import * as APIS from './../constants/apiEndpoints';

export const fetchSummary = (periodInDays = 30) => (dispatch, getState, { api }) => {
    return new api.get(APIS.SUMMARY, {params : { days : periodInDays }} ).then(res => {
        dispatch({
            type : TYPES.FETCH_SUMMARY,
            payload : get(res, 'data.data')
        });
    });
}