import React, { Component } from 'react'
import { Card, Row, Col } from 'antd';
import CardIcon1 from './../assets/images/icon/writing.svg';
import CardIcon2 from './../assets/images/icon/list.svg';
import CardIcon3 from './../assets/images/icon/percent.svg';
import CardIcon4 from './../assets/images/icon/clock.svg';

export default class HeaderBarChart extends Component {
    
    render() {
        const { summary } = this.props;
        return (
            <div className="status-card-section">
                <Row gutter={15} type="flex" justify="space-between" align="middle">
                    <Col xs={24} sm={6} >
                        <Card className="card-item card-secondary-shadow">
                            <div className="icon-sec">
                                <img alt="" src={CardIcon1} className="icon-img" />
                            </div>
                            <div className="info-sec">
                                <div className="number-sec">{summary.totalNotesRead}</div>
                                <h4 className="title">Notes Studies</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={6} >
                        <Card className="card-item card-secondary-shadow">
                            <div className="icon-sec">
                                <img alt="" src={CardIcon2} className="icon-img" />
                            </div>
                            <div className="info-sec">
                                <div className="number-sec">{summary.quizzesTaken}</div>
                                <h4 className="title">Quizzes Taken</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={6} >
                        <Card className="card-item card-secondary-shadow">
                            <div className="icon-sec">
                                <img alt="" src={CardIcon3} className="icon-img" />
                            </div>
                            <div className="info-sec">
                                <div className="number-sec">{summary.averagePercentage}</div>
                                <h4 className="title">Percentage Score</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={6} >
                        <Card className="card-item card-secondary-shadow">
                            <div className="icon-sec">
                                <img alt="" src={CardIcon4} className="icon-img" />
                            </div>
                            <div className="info-sec">
                                <div className="number-sec">{summary.totalReadTime}</div>
                                <h4 className="title">Hours Studies</h4>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}
