import React, { Component } from 'react';
import { Card, Row, Empty, Avatar } from 'antd';
import folderImage from './../assets/images/folder-2.png';
import { Link } from 'react-router-dom';
import * as ROUTES from './../constants/routes';
import CollectionItem from './CollectionItem';

export default class MyCollection extends Component {
  
  render() {
    const { collections, goToCollection,sharedCollection } = this.props;
    return (
      <div className="card-sec1">
      {/* <h4 className="title primary-title">Sub-Collections</h4> */}
      <Row gutter={30}>
        {collections && collections.map(collection => (<CollectionItem sharedCollection={sharedCollection} key={collection.id} goToCollection={goToCollection} collection={collection} />))}
        {
          !collections.length && <Empty description="No Collection added in this collection"/>
        }
      </Row>
    </div>
    )
  }
}
