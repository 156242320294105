import React, { Component } from 'react'
import { Form, Input, Button, Select, Row, Col } from 'antd';


class AddCollectionForm extends Component {


    /**
     * Handle Form Submission
     */
    handleFormSubmit = event => {
        event.preventDefault();
        const { onSubmit } = this.props;

        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log(values);
                onSubmit(values);
            }
        });

    }

    render() {
        const { parentCollection } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="add-form-container">
                <Form onSubmit={this.handleFormSubmit} className="add-collection-form">
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Add to Collection">
                                {
                                    getFieldDecorator('parent_id', {
                                        rules: [{ required: true, message: 'You must select a parent collection' }],
                                        initialValue: parentCollection.id
                                    })(
                                        <Select
                                            disabled={true}
                                            placeholder="Parent Collection"
                                        >
                                            <Select.Option value={parentCollection.id}>{parentCollection.name}</Select.Option>
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Name">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Please add a name for this collection' }],
                                })(
                                    <Input
                                        placeholder="Name"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="login-form-button"> Save </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

export default Form.create({ name: 'addCollectionForm' })(AddCollectionForm);
