import get from 'lodash/get';
/**
 * Constants
 */
import * as TYPES from './../constants/actionTypes';
import * as APIS from './../constants/apiEndpoints';
import map from 'lodash/map';

export const searchUser = (query) => (dispatch, getState, { api }) => {
    return new api.get(`${APIS.SEARCH_USER}${query}`).then(res => {
        return get(res,'data.data.data');
    }).catch((error)=>{
        throw error;
    });
}

export const getUserFamilies = () => (dispatch, getState, { api }) => {
    let url = APIS.GET_USER_FAMILIES;
    return new api.get(url).then(res => {
        dispatch({
            type : TYPES.USER_FAMILIES_FETCHED,
            families : get(res, 'data.data.families')
        });
    });
}

export const getAdminFamilies = () => (dispatch, getState, { api }) => {
    let url = `${APIS.GET_USER_FAMILIES}?role=admin`;

    return new api.get(url).then(res => {
        return get(res, 'data.data.families');
    }).catch((error) => {
        throw error;
    });
}