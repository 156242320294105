import get from 'lodash/get';
/**
 * Constants
 */
import * as TYPES from './../constants/actionTypes';
import * as APIS from './../constants/apiEndpoints';

export const login = (credentials) => (dispatch, getState, { api }) => {
    return new api.post(APIS.LOGIN, credentials ).then(res => {
        api.defaults.headers.common['authorization'] = `Bearer ${res.data.data.token}`;
        dispatch({
            type : TYPES.LOGIN,
            user : get(res, 'data.data')
        });
    });
}

export const signUp = (userData) => (dispatch, getState, { api }) => {
    return new api.post(APIS.SIGNUP, userData ).then(res => {
        api.defaults.headers.common['authorization'] = `Bearer ${res.data.data.token}`;
        dispatch({
            type : TYPES.LOGIN,
            user : get(res, 'data.data')
        });
    });
}

export const logout = () => (dispatch, getState, { api }) => {
        dispatch({
            type : TYPES.LOGOUT
        });
    return true;
}